// Import Libraries and Components
import React from 'react';
import DropdownSelect from '../../../../components/dropdown/Dropdown';
import CalculationChart from '../../../../components/membership/membershipTaskList/CalculationChart';
import QuotationSectionWithBorder from '../../../../components/membership/membershipTaskList/QuotationSectionWithBorder';
import SectionWithBorder from '../../../../components/membership/membershipTaskList/SectionWithBorder';
import ModalBasic from '../../../../pages/component/ModalBasic';
import moment from 'moment';
import {
    TextInput,
    DateInput,
    MonthYear
} from "../../../../components/allTypesInputComponents/AllTypeInputComponents";
import ValidationText from "../../../../utils/validation/ValidationText";
import { StatusType } from "../../../../utils/Constants"
import ButtonLoader from "../../../../components/common/buttonLoader/ButtonLoader";
import SwalServices from '../../../../services/swalServices/SwalServices';
import { CommonValidationMessages, CommonSuccessMessages } from "../../../../utils/Messages";


function MembershipTaskListProcess(props) {
    const handleChangeInPriceTable = (value, name, year, tableName) => {
        props.handleChangeInPriceTable(value, name, year, tableName);
    };

    const swalServices = new SwalServices();

    return (
        <>
            <ModalBasic
                id="add-edit-modal"
                className="membership-add-edit-modal"
                modalOpen={props.setAddeditModalOpen}
                setModalOpen={props.setModalOpen}
                title="Membership Task List Process"
            >
                <div className="2xl:px-14 lg:px-14 pt-4">
                    <div className="">
                        <SectionWithBorder title="Organisation Basic Information">
                            <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Company Name <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    placeholder=""
                                                    type="companyName"
                                                    name="companyName"
                                                    value={props.taskListDetail.companyName}
                                                    identity="Task_List_Detail"
                                                    handleChange={props.handleChange}
                                                    onBlur={(e) => props.validateField("companyName")}
                                                />
                                                <ValidationText error={props.validStateTaskListDetails.error.companyName} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        {
                                            props && props.taskListDetail.companyTypeId === 1 ?
                                                <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                    <label
                                                        className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                        htmlFor="default"
                                                    >
                                                        Company UEN <span className="text-[#AA3361]">*</span>
                                                    </label>
                                                    <div className="disabled-input">
                                                        <TextInput
                                                            placeholder=""
                                                            type="companyUEN"
                                                            name="companyUEN"
                                                            value={props.taskListDetail.companyUEN}
                                                            identity="Task_List_Detail"
                                                            handleChange={props.handleChange}
                                                            onBlur={(e) => props.validateField("companyUEN")}
                                                            disabled={true}
                                                        />
                                                        <ValidationText error={props.validStateTaskListDetails.error.companyUEN} />
                                                    </div>
                                                </div>
                                                :
                                                <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                    <label
                                                        className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                        htmlFor="default"
                                                    >
                                                        Business Registration No <span className="text-[#AA3361]">*</span>
                                                    </label>
                                                    <div className="disabled-input">
                                                        <TextInput
                                                            placeholder=""
                                                            type="businessRegistrationNo"
                                                            name="businessRegistrationNo"
                                                            value={props.taskListDetail.businessRegistrationNo}
                                                            identity="Task_List_Detail"
                                                            handleChange={props.handleChange}
                                                            onBlur={(e) => props.validateField("businessRegistrationNo")}
                                                            disabled={true}
                                                        />
                                                        <ValidationText error={props.validStateTaskListDetails.error.businessRegistrationNo} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Incorporation Date <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <DateInput
                                                name="incorporationDate"
                                                identity="Task_List_Detail"
                                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                                value={moment(props.taskListDetail.incorporationDate).format("YYYY-MM-DD")}
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("incorporationDate")}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Representative First Name{" "} <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                type="firstName"
                                                name="firstName"
                                                value={props.taskListDetail.firstName}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("firstName")}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.firstName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Representative Last Name{" "}
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                type="lastName"
                                                name="lastName"
                                                value={props.taskListDetail.lastName}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("lastName")}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.lastName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Designation{" "}
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                type="designation"
                                                name="designation"
                                                value={props.taskListDetail.designation}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("designation")}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.designation} />
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Business Email {" "}
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                type="email"
                                                name="email"
                                                value={props.taskListDetail.email}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("email")}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.email} />
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Business Contact Number{" "}
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                type="contactNo"
                                                name="contactNo"
                                                value={props.taskListDetail.contactNo}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("contactNo")}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.contactNo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SectionWithBorder>

                        <SectionWithBorder title="Corporate Membership Subscription">
                            <div className="px-7">
                                <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 pb-0">
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Billing Category{" "}
                                                    <span className="text-[#AA3361]">*</span>{" "}
                                                </label>
                                                <DropdownSelect
                                                    drpIdentity={"Billing_Category"}
                                                    optionArray={props.billingCategoryList}
                                                    setFilterParameters={props.setFilterParameters}
                                                    value={props.taskListDetail.billingCategoryId}
                                                    disabled={true}
                                                />
                                                <ValidationText error={props.validStateTaskListDetails.error.billingCategoryId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Updated By{" "}
                                                    {/* <span className="text-[#AA3361]">*</span>{" "} */}
                                                </label>
                                                <DropdownSelect
                                                    drpIdentity={"Updated_By"}
                                                    optionArray={props.updatedByList}
                                                    setFilterParameters={props.setFilterParameters}
                                                    value={props.taskListDetail.updatedBy}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                <label
                                                    className="filter-lable flex w-full text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    <span>Subscription Start Date</span>
                                                    <span className="text-[#AA3361]">*</span>{" "}
                                                </label>
                                                <DateInput
                                                    name="subscriptionStartDate"
                                                    identity="Task_List_Detail"
                                                    className="date-pickers form-input cursor-not-allowed bg-[#e9e9ea] rounded-none w-full shadow-red py-3 text-lg"
                                                    value={moment(props.taskListDetail.subscriptionStartDate).format("YYYY-MM-DD")}
                                                    handleChange={props.handleChange}
                                                    disabled={true}
                                                // onBlur={(e) => props.ValidateAdminDetails("subscriptionStartDate")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Subscription End Date{" "}
                                                    <span className="text-[#AA3361]">*</span>{" "}
                                                </label>
                                                <DateInput
                                                    name="subscriptionEndDate"
                                                    identity="Task_List_Detail"
                                                    className="date-pickers form-input rounded-none cursor-not-allowed bg-[#e9e9ea] w-full shadow-red py-3 text-lg"
                                                    value={moment(props.taskListDetail.subscriptionEndDate).format("YYYY-MM-DD")}
                                                    handleChange={props.handleChange}
                                                    disabled={true}
                                                // onBlur={(e) => props.ValidateAdminDetails("subscriptionEndDate")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Last Updated Date{" "}
                                                    {/* <span className="text-[#AA3361]">*</span>{" "} */}
                                                </label>
                                                <DateInput
                                                    name="lastUpdatedDate"
                                                    identity="Task_List_Detail"
                                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]"
                                                    value={moment(props.taskListDetail.lastUpdatedDate).format("YYYY-MM-DD")}
                                                    handleChange={props.handleChange}
                                                    disabled={true}
                                                // onBlur={(e) => props.ValidateAdminDetails("lastUpdatedDate")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12 pt-3">
                                        <div className="grid grid-cols-12 gap-6 mt-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12 flex justify-end">
                                                <button
                                                    onClick={() => props.navigateToViewCorporateMember()}
                                                    className="col-start-1 col-end-3 text-xl font-bold text-[#901b4c] btn-pink-border w-[350px] p-3">
                                                    View Corporate Information
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7">

                                </div>
                            </div>
                        </SectionWithBorder>

                        <SectionWithBorder title="Pricing Table">
                            <div className="px-7">
                                <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Membership Type{" "}
                                                    <span className="text-[#AA3361]">*</span>{" "}
                                                </label>
                                                <DropdownSelect
                                                    drpIdentity={"Membership_Type"}
                                                    optionArray={props.membershipTypeList}
                                                    setFilterParameters={props.setFilterParameters}
                                                    value={props.taskListDetail.membershipTypeId}
                                                // disabled={true}
                                                />
                                                <ValidationText error={props.validStateTaskListDetails.error.membershipTypeId} />
                                            </div>
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Type of Revenue{" "}
                                                    <span className="text-[#AA3361]">*</span>{" "}
                                                </label>
                                                <DropdownSelect
                                                    drpIdentity={"Revenue_Type"}
                                                    optionArray={props.revenueTypeList}
                                                    setFilterParameters={props.setFilterParameters}
                                                    value={props.taskListDetail.companyAnnualSalesTurnoverId}
                                                // disabled={true}
                                                />
                                                <ValidationText error={props.validStateTaskListDetails.error.companyAnnualSalesTurnoverId} />
                                            </div>
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <label
                                                    className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                    htmlFor="default"
                                                >
                                                    {" "}
                                                    Transaction Type{" "}
                                                    <span className="text-[#AA3361]">*</span>{" "}
                                                </label>
                                                <DropdownSelect
                                                    drpIdentity={"Transaction_Type"}
                                                    optionArray={props.transactionTypeList}
                                                    setFilterParameters={props.setFilterParameters}
                                                    value={props.taskListDetail.transactionTypeId}
                                                    disabled={true}
                                                />
                                                <ValidationText error={props.validStateTaskListDetails.error.transactionTypeId} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="grid grid-cols-2 gap-6 pt-10  pb-8 "> */}
                            <div className="border-r border-[#181818] pl-7 padding-r-0 pr-0">
                                <CalculationChart
                                    title="Base Pricing + Market Campaign Applied"
                                    disabled={true}
                                    showButtons={false}
                                    tableData={props.basePriceTable}
                                    handleChangeInPriceTable={(value, name, year, tableName) =>
                                        handleChangeInPriceTable(value, name, year, tableName)
                                    }
                                    showStatus={StatusType.StateFalse}
                                    membershipTypeId={props.taskListDetail.membershipTypeId}
                                />
                            </div>
                            {/* <div className="padding-r-0">
                                    <div className="pb-8">
                                        <CalculationChart
                                            title="Allowable Discount"
                                            disabled={true}
                                            showButtons={false}
                                            tableData={props.allowableDiscountTable}
                                            handleChangeInPriceTable={(value, name, year, tableName) =>
                                                handleChangeInPriceTable(value, name, year, tableName)
                                            }
                                            showStatus={StatusType.StateFalse}
                                            membershipTypeId={props.taskListDetail.membershipTypeId}
                                        />
                                    </div>
                                </div> */}
                            {/* </div> */}
                            {/* <hr className="custom-hr" /> */}
                            {/* <div className="grid grid-cols-2 gap-6 pt-10 pb-8">
                                <div className="border-r border-[#181818] px-7 active-inputs padding-r-0 pr-0">
                                    <CalculationChart
                                        title="Percentage Discount Setting"
                                        disabled={false}
                                        showButtons={false}
                                        tableData={props.percentageDiscountTable}
                                        handleChangeInPriceTable={(value, name, year, tableName) =>
                                            handleChangeInPriceTable(value, name, year, tableName)
                                        }
                                        showStatus={StatusType.StatusTrue}
                                        membershipTypeId={props.taskListDetail.membershipTypeId}
                                    />
                                </div>
                                <div className="padding-r-0 pr-0">
                                    <CalculationChart
                                        title="System Calculated"
                                        disabled={true}
                                        showButtons={false}
                                        tableData={props.systemCalculatedTable}
                                        handleChangeInPriceTable={(value, name, year, tableName) =>
                                            handleChangeInPriceTable(value, name, year, tableName)
                                        }
                                        membershipTypeId={props.taskListDetail.membershipTypeId}
                                    />
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 pb-8 ">
                                <div className="xl:col-span-12 md:col-span-12 col-span-12 flex justify-center">
                                    {
                                        props.isEscalateLodaing && props.isEscalateLodaing ?
                                            <div className="w-full flex justify-center quatation-send-btn pl-[40%] mb-4">
                                                <ButtonLoader />
                                            </div>
                                            :
                                            <button
                                                className="col-start-1 col-end-3 text-xl font-bold btn btn-pink text-white w-[350px] p-3"
                                                onClick={(e) => props.sendEscalateMailToMember()}
                                            >
                                                Escalate this application
                                            </button>
                                    }
                                </div>
                            </div> */}
                        </SectionWithBorder>

                        {/* <QuotationSectionWithBorder
                            title="Quotation"
                            Yes="Yes"
                            No="No"
                            taskListDetail={props.taskListDetail}
                            handleChange={props.handleChange}
                        > */}
                        <QuotationSectionWithBorder
                            title="Quotation"
                            taskListDetail={props.taskListDetail}
                            handleChange={props.handleChange}
                            yes={props.taskListDetail.sendQuotation}
                            no={props.taskListDetail.sendQuotation}
                        >

                            {/* <p>Send Quotation: {props.taskListDetail.sendQuotation === true ? "Yes" : "No"}</p> */}


                            {/* <p>Send Quotation: 1 {props.taskListDetail.sendQuotation}</p> */}


                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 gap-6 px-8 py-8 ">
                                    <div className="grid grid-cols-12 gap-6 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Membership Tenure
                                                <span className="text-[#AA3361]"> *</span>
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-3 lg:col-span-9 col-span-12 gap-6 ">
                                            <DropdownSelect
                                                drpIdentity={"Membership_Tenure"}
                                                optionArray={props.membershipTenureList}
                                                setFilterParameters={props.setFilterParameters}
                                                value={props.taskListDetail.membershipTenureId}
                                            />
                                            <ValidationText error={props.validStateTaskListDetails.error.membershipTenureId} />
                                        </div>
                                        {/* <div className="2xl:2xl:col-span-3 lg:col-span-9 col-span-12 gap-6 ">
                                            <div className="flex items-center justify-center">
                                                <div className="check-box-sec flex items-center">
                                                    <CheckboxInput
                                                        id="sendQuotation"
                                                        name="sendQuotation"
                                                        className="cursor-pointer h-4 w-4 text-[#AA3361] focus:ring-[#AA3361] rounded check-box-custom"
                                                        checked={props.taskListDetail.sendQuotation}
                                                        value={props.taskListDetail.sendQuotation}
                                                        handleChange={(event) => props.handleChange(event, "Task_List_Detail_CheckboxesRadio")}
                                                    />
                                                    <span className="text-lg font-bold text-[#181818] pl-4">
                                                        Send Quotation
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Quotation Date
                                                <span className="text-[#AA3361]"> *</span>
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-3 lg:col-span-9 col-span-12 gap-6 ">
                                            <MonthYear
                                                name="startMembershipOn"
                                                identity="Task_List_Detail"
                                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                                value={moment(props.taskListDetail.startMembershipOn).format("yyyy-MM")}
                                                handleChange={props.handleChange}

                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-8 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Comments to member
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                                            <TextInput
                                                placeholder=""
                                                type="commentsToMember"
                                                name="commentsToMember"
                                                value={props.taskListDetail.commentsToMember}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                            // onBlur={(e) => props.ValidateAdminDetails("commentsToMember")}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6  mt-8 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Internal Notes
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                                            <TextInput
                                                placeholder=""
                                                type="internalNotes"
                                                name="internalNotes"
                                                value={props.taskListDetail.internalNotes}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                            // onBlur={(e) => props.ValidateAdminDetails("internalNotes")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 px-8 pb-10 ">
                                    <div className="flex items-center justify-between">
                                        {
                                            props.taskListDetail.isQuotation ? <>

                                                <div className="ml-25persent">
                                                    <button className="text-xl font-bold text-[#b92262] underline w-[215px]" onClick={(e) => {
                                                        //e.stopPropagation();
                                                        //setSendQuotation(true);
                                                        props.quotationDownload();
                                                    }} >
                                                        Download Quotation
                                                    </button>
                                                </div>

                                            </> : <>
                                                <div className="ml-25persent">

                                                </div>
                                            </>

                                        }
                                        {/* <div className="">
                                            <button
                                                className="text-xl font-bold text-[#b92262 ] btn-pink-border w-[300px] p-3"
                                                onClick={(e) => {
                                                    //e.stopPropagation();
                                                    //setSendQuotation(true);
                                                    props.sendQuotationEmailToCorporateMember();
                                                }}
                                            >
                                                Send Quotation
                                            </button>
                                        </div> */}
                                        {
                                            props && props.isLoadingQuotation ?

                                                <ButtonLoader className="buttonload text-xl border group relative text-white font-bold text-[#b92262 ] btn-pink w-[300px] p-3 focus:outline-none" />
                                                :
                                                <button
                                                    className="text-xl font-bold text-[#b92262 ] btn-pink-border w-[300px] p-3"
                                                    onClick={(e) => {
                                                        //e.stopPropagation();
                                                        //setSendQuotation(true);
                                                        props.sendQuotationEmailToCorporateMember();
                                                    }}
                                                >
                                                    Send Quotation
                                                </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </QuotationSectionWithBorder>

                        <SectionWithBorder title="Invoice Generation">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 gap-6 px-8 py-8 ">
                                    <div className="grid grid-cols-12 gap-6 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Additional Promotion
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6 ">
                                            <TextInput
                                                placeholder=""
                                                type="additionalPromotion"
                                                name="additionalPromotion"
                                                value={props.taskListDetail.additionalPromotion}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                            // onBlur={(e) => props.ValidateAdminDetails("additionalPromotion")}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-8 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Comments to member
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                                            <TextInput
                                                placeholder=""
                                                type="comments"
                                                name="comments"
                                                value={props.taskListDetail.comments}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                            // onBlur={(e) => props.ValidateAdminDetails("comments")}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6  mt-8 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Internal Notes
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                                            <TextInput
                                                placeholder=""
                                                type="invoiceInternalNotes"
                                                name="invoiceInternalNotes"
                                                value={props.taskListDetail.invoiceInternalNotes}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                            // onBlur={(e) => props.ValidateAdminDetails("invoiceInternalNotes")}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6  mt-8 items-center">
                                        <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                                            <label className="text-lg font-bold text-[#181818]">
                                                Payment Terms
                                            </label>
                                        </div>
                                        <div className="2xl:2xl:col-span-2 lg:col-span-2 col-span-12 gap-6">
                                            <DropdownSelect
                                                drpIdentity={"MembershipTerm"}
                                                optionArray={props.corporateCreditTerms}
                                                setFilterParameters={props.setFilterParameters}
                                                value={props.taskListDetail.membershipTermId}
                                            />
                                        </div>
                                    </div>
                                    {/* corporateCreditTerms */}
                                </div>
                                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 px-8 pb-10 ">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center justify-between">
                                            {/* <button
                                                className={`${props.disablePaymentButton === 3 ? 'btn-disabled-border cursor-not-allowed opacity-50' : 'btn-pink-border'} col-start-1 col-end-3 text-xl font-bold text-[#901b4c] w-[350px] p-3`}
                                                disabled={props.disablePaymentButton}
                                                onClick={() => props.addEditMembershipTask()}
                                            >
                                                Send for Approval
                                            </button> */}
                                            {
                                                props.taskListDetail.isSendForApproval === false && (props.taskListDetail.isReject === false || props.taskListDetail.isReject === null) ? <>
                                                    {
                                                        props && props.isLoadingApprove ?
                                                            <ButtonLoader className="buttonload text-xl border group relative text-white font-bold text-[#b92262 ] btn-pink w-[350px] p-3 focus:outline-none" />
                                                            :
                                                            <button
                                                                className={`${props.disablePaymentButton === 3 ? 'btn-disabled-border cursor-not-allowed opacity-50' : 'btn-pink-border'} col-start-1 col-end-3 text-xl font-bold text-[#901b4c] w-[350px] p-3`}
                                                                disabled={props.disablePaymentButton}
                                                                onClick={
                                                                    () => {
                                                                        swalServices
                                                                            .Confirm(
                                                                                CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
                                                                                CommonSuccessMessages.Confirm_Approve.replace("{0}", "member"),
                                                                                CommonSuccessMessages.Yes_Text,
                                                                                CommonSuccessMessages.No_Text
                                                                            )
                                                                            .then((response) => {
                                                                                if (response) {
                                                                                    props.addEditMembershipTask()
                                                                                }
                                                                            })
                                                                    }
                                                                }
                                                            >
                                                                Send for Approval
                                                            </button>
                                                    }
                                                </>
                                                    :
                                                    <>
                                                        {
                                                            props && props.isLoadingApprove ?
                                                                <ButtonLoader className="buttonload text-xl border group relative text-white font-bold text-[#b92262 ] btn-pink w-[350px] p-3 focus:outline-none" />
                                                                :
                                                                <button
                                                                    className={`${props.isReadyForSendPaymentLink === true && props.taskListDetail.isReject === true ? 'btn-disabled-border cursor-not-allowed opacity-50' : 'btn-pink-border'} col-start-1 col-end-3 text-xl font-bold text-[#901b4c] w-[350px] p-3`}
                                                                    disabled={props.isReadyForSendPaymentLink === true && props.taskListDetail.isReject === false ? false : true}
                                                                    onClick={() => {
                                                                        swalServices
                                                                            .Confirm(
                                                                                CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
                                                                                CommonSuccessMessages.Confirm_Reject.replace("{0}", "member"),
                                                                                CommonSuccessMessages.Yes_Text,
                                                                                CommonSuccessMessages.No_Text
                                                                            )
                                                                            .then((response) => {
                                                                                if (response) {
                                                                                    props.addEditMembershipTask(true)
                                                                                }
                                                                            })

                                                                    }}
                                                                >
                                                                    Reject Application

                                                                </button>
                                                        }
                                                    </>
                                            }
                                        </div>
                                        <div className="flex items-center justify-between pl-2">
                                            {
                                                props.taskListDetail.isReject === true ?
                                                    <>
                                                        {
                                                            props.taskListDetail.isSendForApproval && props.taskListDetail.isReject === true ? <><h1>{props.taskListDetail.rejectedBy}</h1></> : <></>
                                                        }
                                                    </> : <>
                                                        {
                                                            props.taskListDetail.isSendForApproval && props.taskListDetail.isApproved === false ? <><h1>{props.taskListDetail.sendForApprovalBy}</h1></> : <></>
                                                        }
                                                        {
                                                            props.taskListDetail.isApproved === true ? <><h1>{props.taskListDetail.approvedBy}</h1></> : <></>
                                                        }
                                                    </>
                                            }
                                        </div>
                                        <div className="flex items-center justify-between">
                                            {/* <button
                                                className={`col-start-1 col-end-3 text-xl font-bold text-[#AA3361] ${props.disablePaymentButton && props.disablePaymentButton ? 'btn-disabled-border cursor-not-allowed opacity-50' : 'btn-pink-border'} w-[250px] text-red p-3 `}
                                                disabled={props.disablePaymentButton && props.disablePaymentButton ? true : false}
                                                onClick={() => {
                                                    props.addEditMembershipTaskAndSendPaymentLink();
                                                }}
                                            >
                                                Send Payment Link
                                            </button> */}
                                            {
                                                props.isReadyForSendPaymentLink === false ?
                                                    <>

                                                        {/* <button
                                                        className={`col-start-1 col-end-3 text-xl font-bold text-[#AA3361]  btn-disabled-border cursor-not-allowed opacity-50 w-[250px] text-red p-3 `}
                                                        disabled={true}
                                                        onClick={() => {
                                                            props.addEditMembershipTaskAndSendPaymentLink();
                                                        }}
                                                    >
                                                        Send Payment Link
                                                    </button>  */}
                                                        {
                                                            props && props.isLoadingPayment ?
                                                                <ButtonLoader className="buttonload text-xl border group relative text-white font-bold text-[#b92262 ] btn-pink w-[250px] p-3 focus:outline-none" />
                                                                :
                                                                <button
                                                                    className={`col-start-1 col-end-3 text-xl font-bold text-[#AA3361]  btn-disabled-border cursor-not-allowed opacity-50 w-[250px] text-red p-3 `}
                                                                    disabled={true}
                                                                    onClick={() => {
                                                                        props.addEditMembershipTaskAndSendPaymentLink();
                                                                    }}
                                                                >
                                                                    Send Payment Link
                                                                </button>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {/* <button
                                                            className={`col-start-1 col-end-3 text-xl font-bold text-[#AA3361] ${!props.taskListDetail.isSendForApproval && props.taskListDetail.isSendForApproval === false ? 'btn-disabled-border cursor-not-allowed opacity-50' : 'btn-pink-border'} w-[250px] text-red p-3 `}
                                                            disabled={props.taskListDetail.isSendForApproval && props.taskListDetail.isSendForApproval === true ? false : true}
                                                            onClick={() => {
                                                                props.addEditMembershipTaskAndSendPaymentLink();
                                                            }}
                                                        >
                                                            Send Payment Link
                                                        </button> */}
                                                        {
                                                            props && props.isLoadingPayment ?
                                                                <ButtonLoader className="buttonload text-xl border group relative text-white font-bold text-[#b92262 ] btn-pink w-[250px] p-3 focus:outline-none" />
                                                                :
                                                                <button
                                                                    className={`col-start-1 col-end-3 text-xl font-bold text-[#AA3361] ${!props.taskListDetail.isSendForApproval && props.taskListDetail.isSendForApproval === false ? 'btn-disabled-border cursor-not-allowed opacity-50' : 'btn-pink-border'} w-[250px] text-red p-3 `}
                                                                    disabled={props.taskListDetail.isSendForApproval && props.taskListDetail.isSendForApproval === true ? false : true}
                                                                    onClick={() => {
                                                                        props.addEditMembershipTaskAndSendPaymentLink();
                                                                    }}
                                                                >
                                                                    Send Payment Link
                                                                </button>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SectionWithBorder>
                    </div>
                </div>
            </ModalBasic>
        </>
    )
}
export default MembershipTaskListProcess;