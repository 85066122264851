import React, { Component } from "react";
import TextInput from "../../components/UIComponent/TextInput";
import DropdownSelect from "../dropdown/Dropdown";
import {
  CommonValidationMessages,
  ErrorMessage,
  SuccessMessage,
} from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import { Navigate } from "react-router-dom";

//import api services
import FieldManagementServices from "../../services/axiosServices/apiServices/FieldManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import TinyMCEEditor from "../ckEditor/TinyMCEEditor";
import { checkParameters } from "../../utils/Encryption";
import CKClassicEditor from "../ckEditor/CKClassicEditor";

export default class CreateEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.FieldManagementServices = new FieldManagementServices();
    this.SwalServices = new SwalServices();
    this.state = {
      isLoading: false,
      redirect: null,
      projectDropdownList: [
        {
          id: 1,
          name: "SGTech Web",
        },
        {
          id: 2,
          name: "SGTech Admin",
        },
      ],
      emailTemplateDetails: {
        emailTemplateId: 0,
        emailName: "",
        subject: "",
        whereUsed: "",
        projectTypeId: 0,
        description: "",
      },
      validationRule: {
        emailName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email name"
            ),
          },
        ],
        subject: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email subject"
            ),
          },
        ],
        whereUsed: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email where used"
            ),
          },
        ],
        projectTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "project"
            ),
          },
        ],
        description: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "template description"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      createdBy: 0,
      emailTemplateId: 0,
    };
  }

  //Method declaration section start
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let emailTemplateId = params.get("");
    if (emailTemplateId && (emailTemplateId = checkParameters(emailTemplateId, "Number"))) {
      if (emailTemplateId > 0) {
        this.setState({ emailTemplateId: emailTemplateId });
        this.getEmailTemplateById(emailTemplateId);
      }
    }

    // For Set Created by based on admin login
    let cookie = getAuthProps();
    const adminUserId = cookie?.adminUserId ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ createdBy: adminUserId });
    }
    // END

    // this.getEmailTemplateList();
    this.getAllProjectTypeDropdown();
  }

  addEditEmailTemplate = () => {
    let request = {};
    let details = this.state.emailTemplateDetails;
    request.emailTemplateId = details.emailTemplateId;
    request.emailName = details.emailName;
    request.subject = details.subject;
    request.projectTypeId = details.projectTypeId;
    request.whereUsed = details.whereUsed;
    request.createdBy = this.state.createdBy;
    request.createdAppId = 114;
    // let data = details.description.substring(3);
    // let des = data.slice(0, -4);
    // request.description = des;
    request.description = details.description;
    let isValidInput = this.isValidateAllFields();
    if (isValidInput) {
      this.FieldManagementServices.addEditEmailTemplate(request).then(
        (response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (
              response.responseItem.responseContent.errorColumn === "EmailName"
            ) {
              this.SwalServices.Error("email template already exist.");
            } else {
              this.SwalServices.Success(
                SuccessMessage.Insert_Success.replace(
                  "{0}",
                  "Email template details"
                )
              );
              this.setState({ redirect: "/EmailTemplateManagement" });
            }
            // }
          } else {
            this.SwalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        }
      );
    }
  };

  getEmailTemplateById = (id) => {
    this.FieldManagementServices.getEmailTemplateById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          emailTemplateDetails: response.responseItem.responseContent,
        });
      } else {
        this.SwalServices.Error(response.message);
      }
    });
  };

  //project dropdown method
  getAllProjectTypeDropdown = () => {
    this.setState({ isLoading: true });
    this.FieldManagementServices.getAllProjectTypeDropdown().then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            projectDropdownList: response.responseItem.responseContent,
          });
        } else {
          this.SwalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  // API method declaration end

  // Internal method declaration start
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.emailTemplateDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.emailTemplateDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.emailTemplateDetails;
    detail[name] = value;
    this.setState({ emailTemplateDetails: { ...detail } });
  }

  setVideoDescription = (data) => {
    if (typeof data === 'string') {
      let detail = this.state.emailTemplateDetails;
      // var re = new RegExp('<a href="([^"]+)"', "g");
      // var result = data.replace(
      //   re,
      //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
      // );
      detail["description"] = data;
      this.setState({ emailTemplateDetails: { ...detail } });
    }
  };

  onDropdown = (id, drpIdentity) => {
    let details = this.state.emailTemplateDetails;
    if (drpIdentity === "ProjectName") {
      details["projectTypeId"] = id;
      this.validateField("projectTypeId");
    }
    this.setState({ emailTemplateDetails: details });
  };
  // Method declaration end

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className=" heading-part px-7 py-4 border-b">
            <h4 className="small-title font-bold theme-color">
              Email Template
            </h4>
          </div>
          <div className="px-8 pt-10">
            <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full pb-8">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Email Name
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="emailName"
                  value={this.state.emailTemplateDetails.emailName}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("emailName")}
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.emailName}
                  />
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Email Subject
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="subject"
                  value={this.state.emailTemplateDetails.subject}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("subject")}
                />
                <div>
                  <ValidationText error={this.state.validState.error.subject} />
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Email Where Used <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="whereUsed"
                  value={this.state.emailTemplateDetails.whereUsed}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("whereUsed")}
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.whereUsed}
                  />
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Project <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity="ProjectName"
                  optionArray={this.state.projectDropdownList}
                  setFilterParameters={this.onDropdown.bind(this)}
                  value={this.state.emailTemplateDetails.projectTypeId}
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.projectTypeId}
                  />
                </div>
              </div>

              <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12">
                <h2 className="text-xl theme-color mb-1">
                  Template Description:<span className="text-[#AA3361]">*</span>
                </h2>
                <div className="ck-editor-sec">
                  <CKClassicEditor
                    key={"fullPageCKEditor"}
                    onChange={(data) => {
                      this.setVideoDescription(data);
                    }}
                    data={this.state.emailTemplateDetails.description}
                    onBlur={(event, editor) => {
                      this.validateField("description");
                    }}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.description}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*Footer Button End*/}
            <div className="flex flex-wrap justify-between space-x-10 mt-12 pb-8">
              <div>
                <button
                  className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                  onClick={() =>
                    this.setState({ redirect: "/EmailTemplateManagement" })
                  }
                >
                  Back
                </button>
              </div>
              <div>
                {this.state.emailTemplateId &&
                  this.state.emailTemplateId > 0 ? (
                  <button
                    className="w-32 px-7 py-2 btn-sm btn-pink  text-lg text-white"
                    onClick={() => this.addEditEmailTemplate()}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="w-32 px-7 py-2 btn-sm btn-pink  text-lg text-white"
                    onClick={() => this.addEditEmailTemplate()}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
            {/*Footer Button End*/}
          </div>
        </div>
      </div>
    );
  }
}