
import moment from "moment";
import React from "react";
import TimePicker from "../TimePicker.js/TimePicker";
import ActionEditButton from "../UIComponent/ActionTableComponent/ActionEditButton";
import TinyMCEEditor from "../ckEditor/TinyMCEEditor";
import CKClassicEditor from "../ckEditor/CKClassicEditor";

const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';



function AvailabilityScheduleTable(props) {
  // const setHowToGetThere = (data) => {
  //   if (typeof data === 'string') {
  //     let detail = { ...eventTimeSlot };

  //     detail["howToGetThere"] = data;
  //     setEventTimeSlot(detail);
  //   }
  // };
  return (
    <div className="avaibility-table custom-checkbox-set">
      {
        // props.timeSlotList.sort((a,b) => b.eventTimeSlotId - a.eventTimeSlotId).map((timeSlot, timeSlotIndex) =>
        props?.timeSlotList?.map((timeSlot, timeSlotIndex) => {
          return <table className="w-full bg-white border border-[#181818]" key={`TimeSlotTable_${timeSlotIndex}`}>
            {/* Table header */}
            <thead className=" border border-[#181818] bg-[#f6f6f6]">
              <tr>
                <th className="first:pl-8 last:pr-5 w-[20%] px-2 tbl-th whitespace-nowrap text-[#181818]">
                  <div className="flex items-center tble-th-txt text-left font-normal text-xl">
                    {/* <input
                      id="isAdHocActivityEvent"
                      name="isAdHocActivityEvent"
                      type="checkbox"
                      className="cursor-pointer h-4 w-4 text-[#AA3361] focus:ring-[#AA3361] bg-white rounded check-box-custom"
                    //   onChange={(event) => props.handleChangeCheckBox(event)}
                    //   checked={props.sIISCorporateInformation.isAdHocActivityEvent}
                    //   value={props.sIISCorporateInformation.isAdHocActivityEvent}
                    /> */}
                    <label className="ml-5">Sessions</label>
                  </div>
                </th>
                <th className="first:pl-8 pr-16 w-[45%] px-2 tbl-th whitespace-nowrap text-[#181818]">
                  <div className="tble-th-txt text-left font-normal text-xl">
                    Schedules
                  </div>
                </th>
                <th className="first:pl-8 last:pr-5 w-[10%] px-2 tbl-th whitespace-nowrap text-[#181818]">
                  <div className=" tble-th-txt text-center font-normal text-xl">
                    Qty
                  </div>
                </th>
                <th className="first:pl-8 last:pr-5 w-[15%] tbl-th px-2 whitespace-nowrap text-[#181818]">
                  <div className="tble-th-txt text-center font-normal text-xl">
                    Seat booked
                  </div>
                </th>
                <th className="first:pl-8 last:pr-5 w-[10%] tbl-th px-2 whitespace-nowrap text-[#181818]">
                  <div className="tble-th-txt text-center font-normal text-xl">
                    <button className="bg-white border border-[#181818] text-[#181818] px-3 hover:border-[#AA3361] hover:text-[#AA3361]"
                      onClick={() => props.deleteEntryTimeSlotById(timeSlot.eventTimeSlotId)}>
                      Delete
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm custom-tbody">
              {

                // timeSlot.lessons.sort((a,b) => b.eventLessonId - a.eventLessonId).map((lesson, lessonIndex) =>
                timeSlot.lessons.map((lesson, lessonIndex) => {
                  return <>
                    {
                      (lesson.eventLessonId === props.editLessonId) ?
                        <tr className="custom-tbl-td-contribution  border-b border-[#c9c9c9] align-baseline" key={`TimeSlotTable${timeSlotIndex}EditIndex_${lessonIndex}`}>
                          <td className="first:pl-8 text-lg py-6 w-[20%]">
                            <div className="flex items-center tble-th-txt text-left font-normal text-xl">
                              <label className="ml-5 text-[#181818]">{`${alphabets.slice(timeSlotIndex, (timeSlotIndex + 1))}${(lessonIndex + 1)}`}</label>
                            </div>
                          </td>
                          <td className="first:pl-8 text-lg py-6 pr-16 w-[45%]">
                            <div className="text-[#181818] text-xl">
                              <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-12 pl-10">
                                  <input
                                    id={`event${timeSlotIndex}LessonDate${lessonIndex}`}
                                    name="eventLessonDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(lesson.eventLessonDate).format("yyyy-MM-DD")}
                                    onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, lessonIndex)}
                                    min={moment(new Date()).format("yyyy-MM-DD")}
                                  />
                                </div>
                                <div className="col-span-12 pl-10">
                                  <div className="grid grid-cols-12 gap-3">
                                    <div className="col-span-6">
                                      <TimePicker
                                        identity={`${timeSlotIndex}/${lessonIndex}/Event/StartTime`}
                                        setTime={props.setTime}
                                        value={lesson.eventStartTime}
                                      />
                                      {/* <NewTimePicker
                                        identity={`${timeSlotIndex}/${lessonIndex}/Event/StartTime`}
                                        setTime={(time) => props.setLessonTimeForGenerating(time, lessonIndex, 'eventStartTime')}
                                        value={lesson.eventStartTime}
                                      /> */}
                                    </div>
                                    <div className="col-span-6">
                                      <TimePicker
                                        identity={`${timeSlotIndex}/${lessonIndex}/Event/EndTime`}
                                        setTime={props.setTime}
                                        value={lesson.eventEndTime}
                                      />
                                      {/* <NewTimePicker
                                        identity={`${timeSlotIndex}/${lessonIndex}/Event/EndTime`}
                                        setTime={(time) => props.setLessonTimeForGenerating(time, lessonIndex, 'eventStartTime')}
                                        value={lesson.eventStartTime}
                                      /> */}
                                    </div>
                                    {lessonIndex === 0 ?
                                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor="default"
                                        >
                                          How to get there{" "}
                                        </label>
                                        <div
                                          className="ck-editor-sec coursee-location"
                                          id="howToGetThere"
                                        >
                                          <CKClassicEditor
                                            key={timeSlotIndex}
                                            onChange={(data) => props.setHowToGetThere(data, timeSlotIndex)}
                                            data={timeSlot.lessons[lessonIndex].howToGetThere}
                                          />
                                        </div>
                                      </div>
                                      : null}
                                  </div>
                                </div>
                                {(timeSlot.isPhysical || timeSlot.isHybrid) && (props.eventTypeId === 1 || props.eventTypeId === 2 || props.eventTypeId === 4) ?
                                  <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-3">
                                      <div className="col-span-12">
                                        <div className="flex items-center mb-2 pl-[3px]">
                                          {/* <input
                                            id="isAdHocActivityEvent"
                                            name="isAdHocActivityEvent"
                                            type="checkbox"
                                            className="cursor-pointer h-4 w-4 text-[#AA3361] focus:ring-[#AA3361] bg-white rounded check-box-custom"
                                          //   onChange={(event) => props.handleChangeCheckBox(event)}
                                          //   checked={props.sIISCorporateInformation.isAdHocActivityEvent}
                                          //   value={props.sIISCorporateInformation.isAdHocActivityEvent}
                                          /> */}

                                        </div>
                                        <div className="grid grid-cols-12 gap-6 mb-6">
                                          {/* <div className=" col-span-7 w-full pl-10">
                                            <label
                                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                              htmlFor="default"
                                            >
                                              Redirection
                                              <span className="text-[#AA3361]">*</span>{" "}
                                            </label>
                                            <div className="flex items-center justify-start">
                                              <div className=" mr-14">
                                                <label className="flex items-center">
                                                  <input
                                                    type="radio"
                                                    name="radio-buttons-redirection1"
                                                    id={`isRedirection1${timeSlotIndex}`}
                                                    className="form-radio"
                                                    value={timeSlot.isRedirection1}
                                                    onChange={(e) => props.handleRadioButtons(e)}
                                                    checked={timeSlot.isRedirection1 === true}
                                                  />
                                                  <span className="text-xl  font-medium theme-color ml-2">
                                                    Yes
                                                  </span>
                                                </label>
                                              </div>

                                              <div className="m-3">
                                                <label className="flex items-center">
                                                  <input
                                                    type="radio"
                                                    name="radio-buttons-redirection1"
                                                    id={`isRedirection1${timeSlotIndex}`}
                                                    className="form-radio"
                                                    value={timeSlot.isRedirection1}
                                                    onChange={(e) => props.handleRadioButtons(e)}
                                                    checked={timeSlot.isRedirection1 === false}
                                                  />
                                                  <span className="text-xl  font-medium theme-color ml-2">
                                                    No
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div> */}
                                          {timeSlot.isRedirection1 ? (
                                            <div className=" col-span-12 w-full pl-10">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                Redirection Url
                                                <span className="text-[#AA3361]">*</span>{" "}
                                              </label>
                                              {/* <div className={`flex items-center justify-start ${timeSlotList.length > 0 ? "disabled-input" : null}`}> */}
                                              <div className={`grid grid-cols-12 gap-3 w-full items-center justify-start ${timeSlot.isRedirection1 !== true ? "disabled-input" : null}`}>
                                                <div className="col-span-12">
                                                  <div className="">
                                                    <input
                                                      id="redirectURL1"
                                                      className={`form-input rounded-none w-full shadow-red py-3 text-lg text-[#181818] `}
                                                      type="text"
                                                      name="redirectURL1"
                                                      value={timeSlot.redirectURL1}
                                                      onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, lessonIndex)}
                                                    // disabled={timeSlot.isRedirection1 !== true ? true : false}
                                                    //   onBlur={() => props.validateField("resourceName")}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <label
                                      className="filter-lable block font-medium theme-color min-h-30 pl-10"
                                      htmlFor="default"
                                    >
                                      Address
                                    </label>
                                    <div className="grid grid-cols-12 gap-3">
                                      <div className="col-span-12 pl-10">
                                        <input
                                          id="default"
                                          className="form-input rounded-none w-full shadow-red py-3 text-xl text-[#181818] placeholder-[#181818]"
                                          type="text"
                                          name="address"
                                          value={lesson.address}
                                          onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, lessonIndex)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  null
                                }
                                {
                                  (timeSlot.isOnline || timeSlot.isHybrid) && (props.eventTypeId === 1) ?
                                    <div className="col-span-12">
                                      <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-12">
                                          <div className="flex items-center mb-2 pl-[3px]">
                                            {/* <input
                                              id="isAdHocActivityEvent"
                                              name="isAdHocActivityEvent"
                                              type="checkbox"
                                              className="cursor-pointer h-4 w-4 text-[#AA3361] focus:ring-[#AA3361] bg-white rounded check-box-custom"
                                            //   onChange={(event) => props.handleChangeCheckBox(event)}
                                            //   checked={props.sIISCorporateInformation.isAdHocActivityEvent}
                                            //   value={props.sIISCorporateInformation.isAdHocActivityEvent}
                                            /> */}
                                            {
                                              timeSlot.isMeeting === true ?
                                                <label
                                                  className="filter-lable block font-medium theme-color min-h-30 pl-3"
                                                  htmlFor={`zoomMeetingIdT${timeSlotIndex}S${lessonIndex}`}
                                                >
                                                  Meeting Id
                                                </label>
                                                : null
                                            }
                                            {
                                              timeSlot.isWebinar === true ?
                                                <label
                                                  className="filter-lable block font-medium theme-color min-h-30 pl-3"
                                                  htmlFor={`zoomWebinarIdT${timeSlotIndex}S${lessonIndex}`}
                                                >
                                                  Webinar Id
                                                </label>
                                                : null
                                            }
                                            {
                                              timeSlot.isOthers === true ?
                                                <label
                                                  className="filter-lable block font-medium theme-color min-h-30 pl-3"
                                                  htmlFor={`otherZoomDetailT${timeSlotIndex}S${lessonIndex}`}
                                                >
                                                  Other Details
                                                </label>
                                                : null
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-12 gap-3">
                                        <div className={`col-span-12 pl-10 ${props.timeSlotList.bookedSeats > 0 ? "disabled-input" : null}`}>
                                          {
                                            timeSlot.isMeeting === true ?
                                              <input
                                                id={`zoomMeetingIdT${timeSlotIndex}S${lessonIndex}`}
                                                className="form-input rounded-none w-full shadow-red py-3 text-xl text-[#181818] placeholder-[#181818]"
                                                type="text"
                                                name="zoomMeetingId"
                                                onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, lessonIndex)}
                                                value={lesson.zoomMeetingId}
                                                disabled={props.timeSlotList.bookedSeats > 0 ? true : false}
                                              />
                                              : null
                                          }
                                          {
                                            timeSlot.isWebinar === true ?
                                              <input
                                                id={`zoomWebinarIdT${timeSlotIndex}S${lessonIndex}`}
                                                className="form-input rounded-none w-full shadow-red py-3 text-xl text-[#181818] placeholder-[#181818]"
                                                type="text"
                                                name="zoomWebinarId"
                                                onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, lessonIndex)}
                                                value={lesson.zoomWebinarId}
                                                disabled={props.timeSlotList.bookedSeats > 0 ? true : false}
                                              />
                                              : null
                                          }
                                          {
                                            timeSlot.isOthers === true ?
                                              <input
                                                id={`otherZoomDetailT${timeSlotIndex}S${lessonIndex}`}
                                                className="form-input rounded-none w-full shadow-red py-3 text-xl text-[#181818] placeholder-[#181818]"
                                                type="text"
                                                name="otherZoomDetail"
                                                onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, lessonIndex)}
                                                value={lesson.otherZoomDetail}
                                              // disabled={props.timeSlotList.bookedSeats > 0  ? true : false}
                                              />
                                              : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    null
                                }
                              </div>
                            </div>
                          </td>
                          <td className="first:pl-8 text-lg py-6 w-[10%] text-center">
                            {(lessonIndex === 0 ?
                              <div className="text-[#181818] text-xl">
                                <input
                                  id={`availableSeatsT${timeSlotIndex}S${-1}`}
                                  className="form-input rounded-none w-full shadow-red py-3 text-xl text-center text-[#181818] placeholder-[#181818]"
                                  type="text"
                                  name="availableSeats"
                                  value={timeSlot.availableSeats}
                                  onChange={(e) => props.handleChangeInScheduleTable(e, timeSlotIndex, -1)}
                                //   onBlur={() => props.validateField("resourceName")}
                                />
                              </div>
                              :
                              null)}
                          </td>
                          <td className="first:pl-8 text-lg py-6 w-[15%] text-center">
                            {(lessonIndex === 0 ?
                              <div className="text-[#181818] text-xl">{timeSlot.bookedSeats}</div>
                              :
                              null)}

                          </td>
                          <td className="first:pl-8 last:pr-5 text-lg py-6 px-2 whitespace-nowrap tble-td-txt w-[10%] text-center">
                            <div className="action-part text-xl text-center">
                              <button className="bg-white border border-[#181818] text-[#181818] px-3 hover:border-[#AA3361] hover:text-[#AA3361]"
                                onClick={() => props.saveLessonData(timeSlot.eventTimeSlotId, lesson.eventLessonId)}>
                                Save
                              </button>
                            </div>
                          </td>

                        </tr>
                        :
                        <tr className="custom-tbl-td-contribution  border-b border-[#c9c9c9]" key={`TimeSlotTable${timeSlotIndex}Index_${lessonIndex}`}>
                          <td className="first:pl-8 text-lg py-6  w-[20%]">
                            <div className="flex items-center tble-th-txt text-left font-normal text-xl">
                              <label className="ml-5 text-[#181818]">{`${alphabets.slice(timeSlotIndex, (timeSlotIndex + 1))}${(lessonIndex + 1)}`}</label>
                            </div>
                          </td>
                          <td className="first:pl-8 text-lg py-6 pr-16 w-[45%]">
                            <div className="text-[#181818] text-xl">
                              {`${moment(lesson.eventLessonDate).format('dddd, DD MMMM YYYY')}, ${lesson.eventStartTime} - ${lesson.eventEndTime}`}
                              <br />
                              {
                                (timeSlot.isPhysical || timeSlot.isHybrid) && (props.eventTypeId === 1) ?
                                  <p>Address : {lesson.address}</p>
                                  : null
                              }
                              {
                                (timeSlot.isOnline || timeSlot.isHybrid) && (props.eventTypeId === 1) ?
                                  <>
                                    {(timeSlot.isMeeting) ?
                                      <p>Zoom Meeting Id: {lesson.zoomMeetingId}</p>
                                      : null
                                    }
                                    {(timeSlot.isWebinar) ?
                                      <p>Zoom Webinar Id: {lesson.zoomWebinarId}</p>
                                      : null
                                    }
                                    {(timeSlot.isOthers) ?
                                      <p>Zoom Other Detail: {lesson.otherZoomDetail}</p>
                                      : null
                                    }
                                  </>
                                  : null
                              }
                            </div>
                          </td>
                          <td className="first:pl-8 text-lg py-6  w-[10%]">
                            {
                              (lessonIndex === 0) ?
                                <div className="text-[#181818] text-xl text-center">{timeSlot.availableSeats}</div>
                                :
                                null
                            }
                          </td>
                          <td className="first:pl-8 text-lg py-6  w-[15%] text-center">
                            {
                              (lessonIndex === 0) ?
                                <div className="text-[#181818] text-xl">{timeSlot.bookedSeats}</div>
                                :
                                null
                            }
                          </td>
                          <td className="first:pl-8 last:pr-5 text-lg py-6 px-2 whitespace-nowrap tble-td-txt  w-[10%] text-center">
                            <div className="action-part text-xl text-center">
                              <ActionEditButton
                                // value={value}
                                // id={index}
                                // index={index}
                                text="Edit"
                                onActionClick={(value, index, e) => props.editLessonData(timeSlot.eventTimeSlotId, lesson.eventLessonId, e)}
                              />
                            </div>
                          </td>
                        </tr>
                    }
                  </>
                })
              }
            </tbody>
          </table>
        }
        )
      }
    </div>
  );
}

export default AvailabilityScheduleTable;
