import React from "react";
import MyEditor from "../../ckEditor/Editor";
import TinyMCEEditor from "../../ckEditor/TinyMCEEditor";
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import { CloseButtonIcon } from "../../SVGConstants";
import CKClassicEditor from "../../ckEditor/CKClassicEditor";


export default function AdditionalDescription(props) {
  return (
    <>
      {props.additionalDescription.map((descriptionDetails, identity) => {
        return (
          <div className="mt-5 first:mt-0 relative pt-5" key={identity}>
            <div className="close-sign absolute top-0 right-[-10px]">
              <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                onClick={() => props.deleteAdditionalDescriptionById(identity)}>
                <CloseButtonIcon />
              </button>
            </div>
            <label
              className="filter-lable block font-medium  theme-color min-h-30 mb-1"
              htmlFor="default"
            >
              Description <span className="text-[#AA3361]">*</span>
            </label>
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                <div className="ck-txt-editor ">
                  {/* <div className="ck-editor-sec pr-10">
                    <TinyMCEEditor
                              key={`AdditionalDescription${identity}`}
                              onChange={(data) => {
                                props.setEditorData(data, "additionalDescription", identity);
                              }}
                              data={descriptionDetails.additionalDescription}
                            />
                    </div> */}
                  <div className="ck-editor-sec speaker-sec">
                    <CKClassicEditor
                      key={`AdditionalDescription${identity}`}
                      onChange={(data) => {
                        props.setEditorData(data, "additionalDescription", identity);
                      }}
                      data={descriptionDetails.additionalDescription}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <div className="editor-img">
                  <ImageCropperWithUpload
                    key={`Description${identity}`}
                    keyId={`DescriptionImg_${identity}`}
                    height={460}
                    width={814}
                    isCheckValidation={true}
                    MaxFileSize={2097152}
                    MaxFileSizeMessage={"2MB"}
                    onSaveImage={(file, id, imageIdentity) =>
                      props.saveMainImage(file, id, imageIdentity, identity)
                    }
                    imageIdentity={"\\Events\\AdditionalDescription"}
                    intitImage={descriptionDetails.additionalImagePath}
                    aspactRatio={550 / 260}
                    uploadBtn={"Upload Description Image"}
                    dimensionText={
                      "(Dimension: 814px by 460px Maximum Size: 2MB)"
                    }
                  // keyId={props.identity}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
    // <div className="mt-2 first:mt-0">
    //     <label
    //         className="filter-lable block font-medium  theme-color min-h-30 mb-1"
    //         htmlFor="default"
    //     >
    //         Description <span className="text-[#AA3361]">*</span>
    //     </label>
    //     <div className="grid grid-cols-12 gap-6">

    //         <div className="2xl:col-span-8 lg:col-span-8 col-span-12">

    //             <div className="ck-txt-editor ">
    //                 <div className="ck-editor-sec pr-10">
    //                     <MyEditor
    //                         // key={index}
    //                         onChange={(event, editor) => {
    //                             const data = editor.getData();
    //                             props.setEditorData(data, "additionalDescription", props.identity);
    //                         }}
    //                         data={props.descriptionDetails.additionalDescription}
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
    //             <div className="editor-img">
    //                 <ImageCropperWithUpload
    //                     key={`Description${props.identity}`}
    //                     keyId={`DescriptionImg_${props.identity}`}
    //                     height={460}
    //                     width={814}
    //                     isCheckValidation={true}
    //                     MaxFileSize={2097152}
    //                     MaxFileSizeMessage={"2MB"}
    //                     onSaveImage={(
    //                         file,
    //                         id,
    //                         imageIdentity
    //                     ) =>
    //                         props.saveMainImage(
    //                             file,
    //                             id,
    //                             imageIdentity,
    //                             props.identity
    //                         )
    //                     }
    //                     imageIdentity={"\\Events\\AdditionalDescription"}
    //                     intitImage={props.descriptionDetails.additionalImagePath}
    //                     aspactRatio={550 / 260}
    //                     uploadBtn={"Upload Description Image"}
    //                     dimensionText={
    //                         "(Dimension: 814px by 460px Maximum Size: 1MB)"
    //                     }
    //                 // keyId={props.identity}
    //                 />
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
}
