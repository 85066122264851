import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import BookingServices from "../../services/axiosServices/apiServices/BookingServices";
import moment from "moment";
import { checkParameters } from "../../utils/Encryption";
import { formatPrice } from "../../components/common/FormatePrice";
import { saveAs } from "file-saver";
import IndividualMemberService from "../../services/axiosServices/apiServices/IndividualMemberService";

export default class ViewBookingDetail extends Component {
  constructor(props) {
    super(props);
    this.bookingServices = new BookingServices();
    this.individualMemberService = new IndividualMemberService();
    this.state = {
      bookingDetails: {
        bookingAttendeeInformationId: 0,
        bookingId: 0,
        bookingReferenceNo: 0,
        bookingDate: "",
        eventTypeName: "",
        attendeeName: "",
        subTotalPrice: "",
        discountPrice: "",
        totalPrice: "",
        eventAddOnType: "",
        eventTimeSlotId: 0,
        lessonList: [],
        bookingStatus: 0
      },
      showSessionDetails: false,
      bookingDetails1: [],
      showSessionDetails: [],
    };
  }

  getBookingDetailsById = (bookingAttendeeInformationId, bookingId) => {
    this.bookingServices
      .getBookingDetailsById(bookingAttendeeInformationId)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState(
            { bookingDetails: response.responseItem.responseContent },
            () =>
              this.getBookingDetailsByBookingId(
                bookingAttendeeInformationId,
                bookingId
              )
          );
          // let details = {
          //   ...this.state.bookingDetails,
          //   bookingDate: moment(response.responseItem.responseContent.bookingDate).format("YYYY-MM-DD")
          // }
          // this.setState({ bookingDetails: details });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getBookingDetailsByBookingId = (bookingAttendeeInformationId, bookingId) => {
    let request = [bookingAttendeeInformationId, bookingId];
    this.bookingServices
      .getBookingDetailsByBookingId(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            bookingDetails1: response.responseItem.responseContent,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    let params = window.location.href;
    let array = params.split("/");
    let bookingAttendeeInformationId = array[5];
    let bookingId = array[6];
    if (
      bookingAttendeeInformationId &&
      (bookingAttendeeInformationId = checkParameters(
        bookingAttendeeInformationId,
        "Number"
      )) &&
      (bookingId = checkParameters(bookingId, "Number"))
    ) {
      if (
        bookingAttendeeInformationId != null &&
        bookingAttendeeInformationId > 0 &&
        bookingId != null &&
        bookingId > 0
      ) {
        this.getBookingDetailsById(bookingAttendeeInformationId, bookingId);
        // this.getBookingDetailsByBookingId(bookingAttendeeInformationId, bookingId);
      }
    }
    this.setState({
      showSessionDetails: new Array(this.state.bookingDetails1.length).fill(
        false
      ),
    });
    // let BookingAttendeeInformationId = params.get("");
  }
  getInvoiceReceiptPdfByBookingId = (bookingId, type, invoiceReceiptNo) => {
    let reqParams = [bookingId, type];
    this.individualMemberService
      .getInvoiceReceiptPdfByBookingId(reqParams)
      .then((response) => {
        if (response != null) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
          var filename = "";
          if (type === "Receipt") {
            filename = invoiceReceiptNo;
          } else if (type === "Invoice") {
            filename = invoiceReceiptNo;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          // const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          //  const pdfWindow = window.open();
          //  pdfWindow.location.href = fileURL;

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  toggleSessionDetails = () => {
    this.setState((prevState) => ({
      showSessionDetail: !prevState.showSessionDetail,
    }));
  };
  toggleSessionDetail(key) {
    this.setState((prevState) => {
      const showSessionDetails = [...prevState.showSessionDetails];
      showSessionDetails[key] = !showSessionDetails[key];
      return { showSessionDetails };
    });
  }

  render() {
    const { bookingDetails } = this.state;
    const { showSessionDetail } = this.state;
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white mb-7">
          <div className="2xl:py-10 lg:py-7 2xl:pt-0 lg:pt-0 px-5">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-8 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12 pb-10 pt-5">
                    <div className="flex items-center">
                      <div className="left-side w-[50%]">
                        <h2 className="text-xl font-bold text-[#c00000] 2xl:mb-3">
                          {this.state.bookingDetails.eventName}
                        </h2>
                        {/* <h2 className="text-xl theme-color">
                          Trainer Company Name
                        </h2> */}
                      </div>
                      {/* <div className="right-side w-[50%]">
                        <h2 className="text-xl theme-color 2xl:mb-3">
                          3 Jan 2022 (Mon), 9:00AM - 1:00PM
                        </h2>
                        <h2 className="text-xl text-[#c00000]">
                          <div>+ View session details</div>
                        </h2>
                      </div> */}
                      <div className="custom-accordian">
                        <div className="col">
                          <div className="tabs">
                            <div className="tab">
                              <div
                                className="toggle-session"
                                onClick={this.toggleSessionDetails}
                              >
                                <div
                                  className={`plus-signn ${showSessionDetail ? "minus" : "plus"
                                    }`}
                                >
                                  {showSessionDetail ? "-" : "+"}
                                </div>
                                <label className="text-xl text-[#c00000] tab-label text-lg">
                                  {showSessionDetail ? "View" : "View"} session
                                  details
                                </label>
                              </div>

                              {showSessionDetail && (
                                <>
                                  {bookingDetails.lessonList.map(
                                    (item, key) => (
                                      <div key={key} className="">
                                        <h3 className="text-[#757575] text-lg">
                                          {moment(item.eventLessonDate).format(
                                            "DD MMM YYYY (ddd),"
                                          )}{" "}
                                          {item.eventStartTime} -{" "}
                                          {item.eventEndTime}
                                        </h3>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="custom-accordian">
                        <div className="col">
                          <div className="tabs">
                            <div className="tab">
                              <input
                                type="checkbox"
                                id={`Acc_B/_First/`}
                              />
                              <label
                                className="text-xl text-[#c00000] tab-label"
                                htmlFor={`Acc_B/_First/`}
                              >
                                View session
                                details
                              </label>

                              {this.state.bookingDetails.lessonList.map((item, key) => (
                                <div key={key} className="tab-content"
                                >
                                  <h3 className="text-[#757575] text-lg">
                                    {moment(
                                      item.eventLessonDate
                                    ).format(
                                      "DD MMM YYYY (ddd),"
                                    )}
                                    {
                                      item.eventStartTime
                                    }{" "}
                                    -{" "}
                                    {
                                      item.eventEndTime
                                    }
                                  </h3>
                                </div>
                              ))}

                             
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
                <div className="flex justify-end flex-col items-end">
                  <div className="right-status-label relative top-[9px] left-[20px]">
                    <span className="text-[14px] lg:text-[16px] text-white font-semibold bg-[#7a9763] px-3 py-3">
                      {/* Booked */}
                      {this.state.bookingDetails.bookingStatus}
                    </span>
                  </div>
                  <div
                    className="relative top-[9px] left-0 mt-[50px] py-3 btn .btn-red-border border fontt"
                    style={{ padding: "unset" }}
                  >
                    <button
                      className="fontt btn btn-red-border border underline"
                      onClick={() => this.getInvoiceReceiptPdfByBookingId(this.state.bookingDetails.bookingId, "Invoice", this.state.bookingDetails.invoiceNo)}
                    >
                      <span className=" fontt font-semibold tracking-normal underline text-[#c00000]">
                        View Invoice
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color font-bold w-[50%]">
                            Booking Ref:
                          </h2>
                          <h2 className="text-xl theme-color font-bold  w-[50%]">
                            {this.state.bookingDetails.bookingReferenceNo
                              ? this.state.bookingDetails.bookingReferenceNo
                              : "N.A"}
                          </h2>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">
                            Booking Date:
                          </h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {moment(
                              this.state.bookingDetails.bookingDate
                            ).format("DD MMM YYYY")}
                          </h2>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">Type:</h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {this.state.bookingDetails.eventTypeName}
                          </h2>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">
                            Optional Add-on:
                          </h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {this.state.bookingDetails.eventAddOnType
                              ? this.state.bookingDetails.eventAddOnType
                              : "N.A"}
                          </h2>
                        </div>
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">
                            Original Price:
                          </h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {/* {this.state.bookingDetails.subTotalPrice} */}
                            {formatPrice(
                              this.state.bookingDetails.subTotalPrice
                            )}
                          </h2>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">
                            Discount:
                          </h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {this.state.bookingDetails.discountPrice}
                          </h2>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">
                            Price Paid:
                          </h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {/* {this.state.bookingDetails.totalPrice} */}
                            {formatPrice(this.state.bookingDetails.totalPrice)}
                          </h2>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-xl theme-color w-[50%]">
                            Attendee Name:
                          </h2>
                          <h2 className="text-xl theme-color w-[50%]">
                            {this.state.bookingDetails.attendeeName}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-2 lg:col-span-3 col-span-12 2xl:pt-7 lg:mt-4 items-center justify-end">
                <img
                  className=" w-[120px] mx-auto"
                  src="/qrcode1.jpg"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </div>
        {this.state.bookingDetails1.map((item, key) => (
          <div className="bg-white mb-7" key={key}>
            <div className="2xl:py-10 lg:py-7 2xl:pt-0 lg:pt-0 px-5">
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-8 items-center">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 pb-10 pt-5">
                      <div className="flex items-center ">
                        <div className="left-side w-[50%]">
                          <h2 className="text-xl font-bold text-[#c00000] 2xl:mb-3">
                            {item.eventName}
                          </h2>
                          {/* <h2 className="text-xl theme-color">
                                        Trainer Company Name
                                      </h2> */}
                        </div>
                        {/* <div className="right-side w-[50%]">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            3 Jan 2022 (Mon), 9:00AM - 1:00PM
                          </h2>
                          <h2 className="text-xl text-[#c00000]">
                            <div>+ View session details</div>
                          </h2>
                        </div> */}

                        {/* <div className="custom-accordian">
                          <div className="col">
                            <div className="tabs">
                              <div className="tab">
                                <input
                                  type="checkbox"
                                  id={`Acc_B/${key}_L/`}
                                />
                                <label
                                  className="text-xl text-[#c00000] tab-label"
                                  htmlFor={`Acc_B/${key}_L/`}
                                >
                                  View session
                                  details
                                </label>
                                {item.lessonList.map(
                                  (
                                    les,
                                    lesIndex
                                  ) => {
                                    return (
                                      <div
                                        className="tab-content"
                                        key={
                                          lesIndex
                                        }
                                      >
                                        <h3 className="text-[#757575] text-lg">
                                          {moment(
                                            les.eventLessonDate
                                          ).format(
                                            "DD MMM YYYY (ddd),"
                                          )}
                                          {
                                            les.eventStartTime
                                          }{" "}
                                          -{" "}
                                          {
                                            les.eventEndTime
                                          }
                                        </h3>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="custom-accordian">
                          <div className="col">
                            <div className="tabs">
                              {/* {this.state.bookingDetails1.map((item, index) => ( */}
                              <div className="tab" key={key}>
                                <div
                                  className="toggle-session"
                                  onClick={() => this.toggleSessionDetail(key)}
                                >
                                  <div className="plus-signn">
                                    {this.state.showSessionDetails[key]
                                      ? "-"
                                      : "+"}
                                  </div>
                                  <label className="text-xl text-[#c00000] tab-label">
                                    {this.state.showSessionDetails[key]
                                      ? "View"
                                      : "View"}{" "}
                                    session details
                                  </label>
                                </div>

                                {this.state.showSessionDetails[key] && (
                                  <>
                                    {item.lessonList.map(
                                      (session, sessionIndex) => (
                                        <div
                                          key={sessionIndex}
                                          className="tab-content"
                                        >
                                          <h3 className="text-[#757575] text-lg">
                                            {moment(
                                              session.eventLessonDate
                                            ).format("DD MMM YYYY (ddd),")}{" "}
                                            {session.eventStartTime} -{" "}
                                            {session.eventEndTime}
                                          </h3>
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
                  <div className="flex justify-end flex-col items-end">
                    <div className="right-status-label relative top-[9px] left-[20px]">
                      <span className="text-[14px] lg:text-[16px] text-white font-semibold bg-[#7a9763] px-3 py-3">
                        {/* Booked */}
                        {this.state.bookingDetails.bookingStatus}
                      </span>
                    </div>
                    {/* <div className="relative top-[9px] left-0 mr-6 mt-[50px] ">
                      <button className="btn btn-red-border text-xl border underline">
                        View Invoice
                      </button>
                    </div> */}
                    <div
                      className="relative top-[9px] left-0 mt-[50px] py-3 btn .btn-red-border border fontt"
                      style={{ padding: "unset" }}
                    >
                      <button
                        className="fontt btn btn-red-border border underline"
                        onClick={() => this.getInvoiceReceiptPdfByBookingId(this.state.bookingDetails.bookingId, "Invoice", this.state.bookingDetails.invoiceNo)}
                      >
                        <span className=" fontt font-semibold tracking-normal underline text-[#c00000]">
                          View Invoice
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color font-bold  w-[50%]">
                              Booking Ref:
                            </h2>
                            <h2 className="text-xl theme-color font-bold  w-[50%]">
                              {item.bookingReferenceNo
                                ? item.bookingReferenceNo
                                : "N.A"}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Booking Date:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {moment(item.bookingDate).format("DD MMM YYYY")}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Type:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {item.eventTypeName}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Optional Add-on:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {item.eventAddOnType
                                ? item.eventAddOnType
                                : "N.A"}
                            </h2>
                          </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Original Price:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {/* {item.subTotalPrice} */}
                              {formatPrice(item.subTotalPrice)}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Discount:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {formatPrice(item.discountPrice)}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Price Paid:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {/* {item.totalPrice} */}
                              {formatPrice(item.totalPrice)}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <h2 className="text-xl theme-color w-[50%]">
                              Attendee Name:
                            </h2>
                            <h2 className="text-xl theme-color w-[50%]">
                              {item.attendeeName}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-2 lg:col-span-3 col-span-12 2xl:pt-7 lg:mt-4 items-center justify-end">
                  <img
                    className=" w-[120px] mx-auto"
                    src="/qrcode1.jpg"
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="bg-white hidden">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Payment Cancellation Details
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-12 pt-8">
            <div className="col-span-12">
              <div className="mt-5 flex items-center">
                <label className="flex items-center mr-16">
                  <input
                    type="radio"
                    name="radio-buttons"
                    id="isPage"
                    className="form-radio"
                  // value={props.resource.isPage}
                  // onChange={(event) => props.handleRadioButtons(event)}
                  // checked={props.resource.isPage === true}
                  />
                  <span className="text-xl  font-medium theme-color ml-2">
                    Amount to Refund
                  </span>
                </label>
                <div className="dollor-sign dollor-sign-custom">
                  <input
                    className="custom-input pl-[70px]"
                    type="text"
                    name="donationAmount"
                  ></input>
                </div>
              </div>

              <div className="col-span-12 ">
                <div className="mt-5 flex items-center">
                  <label className="flex items-center mr-8">
                    <input
                      type="radio"
                      name="radio-buttons"
                      id="isPage"
                      className="form-radio"
                    // value={props.resource.isPage}
                    // onChange={(event) => props.handleRadioButtons(event)}
                    // checked={props.resource.isPage === true}
                    />
                    <span className="text-xl  font-medium theme-color ml-2">
                      Percentage to Refund
                    </span>
                  </label>
                  <div className="prasantage-input with-border-line ">
                    <input
                      className="input-texts form-input rounded-none shadow-red py-3 pr-[70px] text-lg"
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="grid grid-cols-12 pt-7 gap-6">
                  <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full mb-7 input-sec-texts">
                    {/* <h2 className="text-xl font-bold theme-color mb-3">50% Cancellation Refund</h2> */}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-6 w-full items-center pb-12">
                <div className="col-span-12 pb-10 parent-tbl">
                  <div className="grid grid-cols-12 gap-6 items-end">
                    <div className="col-span-9 w-full">
                      <h2 className="text-xl font-bold theme-color mb-3">
                        50% Cancellation Refund
                      </h2>
                      <div className="view-booking-detail-table">
                        <table className="w-full mx-auto my-5 fixed_header border border-collapse">
                          <thead>
                            <tr className="text-base font-bold flex">
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[25%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Breakdown
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[25%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Amount
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[25%]">
                                <div className="font-bold tble-th-txt text-left">
                                  GST
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[25%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Total Amount
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="text-sm custom-tbody">
                            {/* {this.state.bookingDetails.map(
                              (e) => {
                                return (
                                  <tr className="custom-tbl-td flex items-center">
                                    <td className="px-2 first:pl-5 last:pr-5 border whitespace-nowrap tble-td-txt text-[#757575] w-[25%] ">
                                      {e.breakdown}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 border whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                      {e.amount}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 border whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                      {e.gst}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 border whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                      {e.totalamount}
                                    </td>
                                  </tr>
                                );
                              }
                            )} */}
                            {/* <tr className="grid justify-items-center">
                                        <td className="text-lg text-[#757575]	">No Records Found</td>
                                         </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className=" 2xl:col-span-3  lg:col-span-3  col-span-12 w-full pl-5">
                      <div className="credit-btn pb-5 w-full">
                        <button className="btn btn-red text-xl text-white">
                          Generate Credit Note
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between space-x-10 mt-12">
          <NavLink
            to="/booking/ViewNewBookings"
            className="btn btn-gray text-xl text-white"
          >
            {" "}
            Back
          </NavLink>
          {/* <button
            className="btn btn-red text-xl text-white"
          //   onClick={() => this.savePageDetails()}
          >
            Cancel Booking
          </button> */}
          {/* } */}
        </div>
      </div>
    );
  }
}
