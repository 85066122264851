import moment from "moment";
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import DropdownSelect from "../../components/dropdown/Dropdown";
import { DeleteButtonIcon } from "../../components/SVGConstants";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { checkParameters } from "../../utils/Encryption";
import { CommonValidationMessages, ErrorMessage } from "../../utils/Messages";
import { convertToDecimal, isNullNumber } from "../../utils/Utils";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import CorporateMemberServices from "../../services/axiosServices/apiServices/MembershipCorporateServices";
import { formatPrice } from "../../components/common/FormatePrice";

export default class AdhocInvoices extends Component {
  constructor(props) {
    super(props);
    this.financeService = new FinanceServices();
    this.corporateMemberServices = new CorporateMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      financeAdhocInvoiceId: 0,
      redirect: null,
      route: null,
      isDisableSubmitButton: false,
      adhokInvoiceDetail: {
        financeAdhocInvoiceId: 0,
        adhokInvoiceTypeId: 0,
        financeAdhocCountryId: 0,
        adhocAttentionTo: "",
        adhokEmailAddress: "",
        adhocBillingAddress1: "",
        adhocBillingAddress2: "",
        adhocBillingAddress3: "",
        adhokPostalCode: "",
        adhokPONumber: "",
        isGSTYes: true,
        isGSTZeroRated: false,
        isGSTNotApplicable: false,
        adhokSubtotalAmount: 0,
        adhokGSTAmount: 0,
        adhokTotalAmount: 0,
        companyName: "",
        paymentsCreditsApplied: 0,
        netTotal: 0,
        invoiceDate: "",
        adhokInvoiceDescription: "",
        adhokInvoiceItems: {
          adhokInvoiceItemId: 0,
          adhokXeroDescription: "",
          adhokInvoiceDescription: "",
          adhokQuantity: 0,
          adhokAmount: 0,
          financeAccountCodeId: 0,
          financeClassCodeId: 0,


          //revenueAccountCodeId: 0,
          //revenueClassCodeId: 0,
        },
        paymentTermId: 0,
      },
      adhokInvoiceTypes: [],
      financeClassCodes: [],
      countryList: [],
      PaymentTermList: [],
      financeAccountCodes: [],
      invoiceItemList: [],
      //gstPercentage: 0,
      isEdit: null,

      serviceDetail: {
        isContinuousPeriod: false,
        isSpecificMonth: false,
        isDrawdown: false,
        serviceStartMonth: moment().format("yyyy-MM-DD"),
        serviceMonthCount: 0,
        financeAdhocInvoiceId: 0,
        serviceItem: [],
      },
      invoiceDetail: {
        invoiceno: "",
        invoceDescription: "",
        amount: "",
        invoiceDate: "",
      },
      isCompleted: false,
      addMonthList: [],
      // Validation
      validationRule: {
        paymentTermId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "payment term"
            ),
          },
        ],
        adhokInvoiceTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "invoice type"
            ),
          },
        ],
        financeAdhocCountryId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "country"
            ),
          },
        ],
        adhocAttentionTo: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "attention"
            ),
          },
        ],
        companyName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "company name"
            ),
          },
        ],
        adhokEmailAddress: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email address"
            ),
          },
          {
            type: "email",
            message: CommonValidationMessages.EmailAddressNotValid.replace(
              "{0}",
              "email address"
            ),
          },
        ],
        adhocBillingAddress1: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "billing address line 1"
            ),
          },
        ],
        // adhocBillingAddress2: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "billing address line 2"
        //     ),
        //   },
        // ],
        // adhocBillingAddress3: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "billing address line 3"
        //     ),
        //   },
        // ],
        adhokPostalCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "postal code"
            ),
          },
        ],
        // adhokPONumber: [
        //   {
        //     type: 'require',
        //     message: CommonValidationMessages.FieldRequired.replace('{0}', 'PO number')
        //   }
        // ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      isLoading: false,
      submitExternalInvoice: false,
      validationRuleForInvoiceIteme: {
        adhokXeroDescription: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "xero description"
            ),
          },
        ],
        adhokInvoiceDescription: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "invoice description"
            ),
          },
        ],
        adhokQuantity: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "quantity"
            ),
          },
        ],
        adhokAmount: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "amount"
            ),
          },
        ],
        financeAccountCodeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "account code"
            ),
          },
        ],
        financeClassCodeId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "class code"
            ),
          },
        ],
        // revenueAccountCodeId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace(
        //       "{0}",
        //       "revenue account code"
        //     ),
        //   },
        // ],
        // revenueClassCodeId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace(
        //       "{0}",
        //       "revenue class code"
        //     ),
        //   },
        // ],
      },
      validState2: {
        isValid: true,
        error: {},
      },
      array: {
        financeExternalServiceItemId: 0,
        serviceStartMonth: "",
        serviceDrawdownAmount: 0,
        serviceAmount: 0,
      },
      specificMonthAmountTotal: 0,
      isSaveItems: false,
      resultTableList: [],
      resultTableData: {},
      addNewDrawdown: {
        serviceStartMonth: "",
        serviceDrawdownAmount: 0,
        serviceAmount: 0,
      },
      isDrawdownComplete: false,
      validationRulesForIsContinuousPeriod: {
        serviceAmount: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "amount"
            ),
          },
        ],
      },
      validationRulesForIsSpecificMonth: {
        serviceStartMonth: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "date"
            ),
          },
        ],
        serviceAmount: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "amount"
            ),
          },
        ],
      },
      valid: {
        isValid: true,
        error: {},
      },
      gstRate: 0,
      gstAmount: 0,
      gstPercentage: 0,
    };
  }

  isValidateServiceItems = () => {
    let newValidState = {
      isValid: true,
      error: {},
    };
    let tempArr = [];
    let data = this.state.valid;
    if (this.state.serviceDetail.isContinuousPeriod) {
      this.state.addMonthList.map((item, keyId) => {
        let changes = isValidForm(
          item,
          this.state.validationRulesForIsContinuousPeriod,
          this.state.valid
        );
        if (changes?.error != null && changes.isValid === false) {
          let field = `serviceAmount_${keyId}`;
          let message = changes.error.serviceAmount;
          newValidState.isValid = false;
          tempArr[field] = message;
        }
      });
    }
    if (this.state.serviceDetail.isSpecificMonth) {
      this.state.addMonthList.map((item, keyId) => {
        let changes = isValidForm(
          item,
          this.state.validationRulesForIsSpecificMonth,
          this.state.valid
        );
        if (
          changes?.error != null &&
          changes.isValid === false &&
          changes.error.serviceStartMonth
        ) {
          let field = `serviceStartMonth_${keyId}`;
          newValidState.isValid = false;
          tempArr[field] = "Please select date";
        }
        if (
          changes?.error != null &&
          changes.isValid === false &&
          changes.error.serviceAmount
        ) {
          let field = `serviceAmount_${keyId}`;
          newValidState.isValid = false;
          tempArr[field] = "Please enter amount";
        }
      });
    }
    data.error = tempArr;
    data.isValid = newValidState.isValid;
    this.setState({ valid: data });
    return newValidState.isValid;
  };

  validateServiceItemsField = (key, providedState, keyId) => {
    providedState = providedState[keyId];
    let keys = key.split("_");
    const newValidState = validate(
      keys[0],
      providedState,
      this.state.validationRulesForIsContinuousPeriod,
      this.state.valid
    );
    let changeValue = {};
    changeValue.error = {} = newValidState.error;
    changeValue.error[key] = newValidState.error.serviceAmount;
    changeValue.isValid = newValidState.isValid;
    delete changeValue.error["serviceAmount"];
    this.setState({ valid: changeValue });
  };

  getAllDropdownsForFinanceAdhokInvoices = () => {
    this.financeService
      .getAllDropdownsForFinanceAdhokInvoices()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let adhokInvoiceTypesArry =
            response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "AdhokInvoiceTypes"
            );
          let financeClassCodesArry =
            response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "FinanceClassCodes"
            );
          let financeAccountCodesArry =
            response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "FinanceAccountCodes"
            );
          let gstPercentage = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "GSTPercentage"
          );
          adhokInvoiceTypesArry = adhokInvoiceTypesArry.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          financeClassCodesArry = financeClassCodesArry.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          financeAccountCodesArry = financeAccountCodesArry.map((p) => ({
            id: p.id,
            name: p.name,
          }));

          let percentage = Number(gstPercentage[0].name);
          this.setState(
            {
              adhokInvoiceTypes: adhokInvoiceTypesArry,
              financeClassCodes: financeClassCodesArry,
              financeAccountCodes: financeAccountCodesArry,
              gstPercentage: percentage,
            },
            () => {
              this.getGSTRate();
            }
          );
        }
      });
  };

  getAllDropdownForCorporateMember = () => {
    this.corporateMemberServices
      .getAllDropdownForCorporateMember()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let countries = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Countries"
          );
          countries.map(function (item) {
            delete item["dropdownName"];
          });
          // countries = countries.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ countryList: countries });

          let PaymentTerm = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "CorporateCreditTerms"
          );

          PaymentTerm.map(function (item) {
            delete item["dropdownName"];
          })
          this.setState({ PaymentTermList: PaymentTerm });

        }
      });
  };

  getGSTRate = () => {
    this.financeService.getGSTRate().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = response.responseItem.responseContent.gst
          ? response.responseItem.responseContent.gst
          : 0;
        let gstRate = 0;
        let gstAmount = 0;
        if (data > 0) {
          gstRate = data / 100;
          gstAmount = 1 + data / 100;
        } else {
          gstRate = 0;
        }
        this.setState({
          gstRate: gstRate,
          gstAmount: gstAmount,
          gstPercentage: data,
        });
      }
    });
  };

  addEditFinanceAdhokInvoiceDetail = () => {
    //this.setState({ isLoading : true})

    if (this.isValidateAllFields()) {
      this.setState({ submitExternalInvoice: true });
      let request = {};
      request.financeAdhocInvoiceId =
        this.state.adhokInvoiceDetail.financeAdhocInvoiceId;
      request.adhokInvoiceTypeId =
        this.state.adhokInvoiceDetail.adhokInvoiceTypeId;
      request.adhocAttentionTo = this.state.adhokInvoiceDetail.adhocAttentionTo;
      request.adhokEmailAddress =
        this.state.adhokInvoiceDetail.adhokEmailAddress;
      request.adhocBillingAddress1 =
        this.state.adhokInvoiceDetail.adhocBillingAddress1;
      request.adhocBillingAddress2 =
        this.state.adhokInvoiceDetail.adhocBillingAddress2;
      request.adhocBillingAddress3 =
        this.state.adhokInvoiceDetail.adhocBillingAddress3;
      request.adhokPostalCode = this.state.adhokInvoiceDetail.adhokPostalCode;
      request.adhokPONumber = this.state.adhokInvoiceDetail.adhokPONumber;
      request.isGSTYes = this.state.adhokInvoiceDetail.isGSTYes;
      request.isGSTZeroRated = this.state.adhokInvoiceDetail.isGSTZeroRated;
      request.isGSTNotApplicable =
        this.state.adhokInvoiceDetail.isGSTNotApplicable;
      request.adhokSubtotalAmount =
        this.state.adhokInvoiceDetail.adhokSubtotalAmount;
      request.adhokGSTAmount = this.state.adhokInvoiceDetail.adhokGSTAmount;
      request.adhokTotalAmount = this.state.adhokInvoiceDetail.adhokTotalAmount;
      request.companyName = this.state.adhokInvoiceDetail.companyName;
      request.paymentsCreditsApplied =
        this.state.adhokInvoiceDetail.paymentsCreditsApplied;
      request.netTotal = this.state.adhokInvoiceDetail.netTotal;
      request.financeAdhocCountryId =
        this.state.adhokInvoiceDetail.financeAdhocCountryId;
      request.adhokInvoiceItems = [];
      request.adhokInvoiceItems = [...this.state.invoiceItemList];
      request.paymentTermId = this.state.adhokInvoiceDetail.paymentTermId;
      request.invoiceDate = this.state.invoiceDetail.invoiceDate;
      request.adhokInvoiceDescription = this.state.invoiceDetail.invoceDescription;

      // set gst rate.
      if (request.isGSTYes) {
        request.gSTRate = this.state.gstPercentage;
      } else {
        request.gSTRate = 0;
      }
      //this.setState({ submitExternalInvoice: true }, () => {
        this.setState({ isLoading : true})
      this.financeService
        .addEditFinanceAdhokInvoiceDetail(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorType === "Exists") {
              this.swalServices.Error(
                response.responseItem.responseContent.errorMessage
              );
              this.setState({ submitExternalInvoice: false });
            } else if (
              response.responseItem.responseContent.errorType !== "Exists"
            ) {
              this.swalServices.Success("External invoice added successfully.");
              this.setState({ redirect: "/financial/ExternalInvoices" });
            }
          } else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          //this.setState({ isLoading: false });
        });
        this.setState({ isLoading: false })
      //});
      //this.setState({ submitExternalInvoice: false });
    }
    //this.setState({ submitExternalInvoice: false });
  };

  resetFields = () => {
    let removeItemData = { ...this.state.adhokInvoiceDetail };
    removeItemData.adhokInvoiceItems.adhokXeroDescription = "";
    removeItemData.adhokInvoiceItems.adhokInvoiceDescription = "";
    removeItemData.adhokInvoiceItems.adhokQuantity = 0;
    removeItemData.adhokInvoiceItems.adhokAmount = 0;
    // removeItemData.adhokInvoiceItems.financeAccountCodeId = 0;
    // removeItemData.adhokInvoiceItems.financeAccountCodeId = 0;
    removeItemData.adhokInvoiceItems.paymentsCreditsApplied = 0;
    removeItemData.adhokInvoiceItems.netTotal = 0;
    removeItemData.adhokInvoiceItems.revenueAccountCodeId = 0;
    removeItemData.adhokInvoiceItems.revenueClassCodeId = 0;
    this.setState({ adhokInvoiceDetail: removeItemData }, () => {
      this.countAmount();
    });
  };

  countAmount = () => {
    let list = this.state.invoiceItemList;
    let adhokData = { ...this.state.adhokInvoiceDetail };
    ////
    // let gstAmount = this.state.gstAmount;
    //let gstRate = this.state.gstRate;
    //let subtotal = 0;
    // list.map((item) => {
    //   let test = convertToDecimal((item.adhokAmount).toFixed(2))
    //   subtotal = subtotal + (test/gstAmount);
    // });

    /////
    let gstPercent =
      adhokData.isGSTYes === true ? this.state.gstPercentage : Number(0);
    let total = 0;
    list.map((item) => {
      total = total + item.adhokAmount;
      // let price = (item.adhokAmount/gstAmount);
      // item.adhokAmount = convertToDecimal((price).toFixed(2));
    });

    let subtotal = total.toFixed(2);
    let subTotalAmount = convertToDecimal(subtotal);
    let countGST = (subTotalAmount * (gstPercent / 100)).toFixed(2);
    let countGSTAmount = convertToDecimal(countGST);
    /// test
    //let subTotalAmount = convertToDecimal((subtotal).toFixed(2));
    //let countGST = (gstRate * subTotalAmount);
    //let countGSTAmount = convertToDecimal((countGST).toFixed(2));
    //let t = subTotalAmount + countGSTAmount;
    //let t = total;
    // tesT
    let data = { ...this.state.adhokInvoiceDetail };
    let t = (total + countGSTAmount).toFixed(2);
    let totalAmount = convertToDecimal(t);
    data.adhokSubtotalAmount = subTotalAmount;
    data.adhokGSTAmount = countGSTAmount;
    data.adhokTotalAmount = totalAmount;

    data.paymentsCreditsApplied = 0;
    data.netTotal = data.adhokTotalAmount;
    this.setState({ adhokInvoiceDetail: data });
  };

  addInvoiceItem = () => {
    let tableList = this.state.invoiceItemList;
    let data = { ...this.state.adhokInvoiceDetail.adhokInvoiceItems };
    // let gstAmount = this.state.gstAmount;
    if (this.isValidateInvoiceItemeAllFields()) {
      if (this.state.isEdit !== null) {
        // tableList.map((temp)=>{
        //   temp.adhokAmount = (temp.adhokAmount * gstAmount);
        // });
        tableList[this.state.isEdit] = data;
      } else {
        // tableList.map((temp)=>{
        //   temp.adhokAmount = (temp.adhokAmount * gstAmount);
        // });
        let array = {};
        array.adhokInvoiceDescription = data.adhokInvoiceDescription;
        array.adhokQuantity = data.adhokQuantity;
        array.adhokAmount = data.adhokAmount;
        array.adhokXeroDescription = data.adhokXeroDescription;
        array.financeAccountCodeId = data.financeAccountCodeId;
        array.financeClassCodeId = data.financeClassCodeId;
        array.revenueAccountCodeId = data.revenueAccountCodeId;
        array.revenueClassCodeId = data.revenueClassCodeId;
        tableList.push(array);
      }
      this.setState({ invoiceItemList: tableList, isEdit: null });
      this.resetFields();
    }
  };

  getFinanceExternalServiceDetailById = () => {
    this.financeService
      .getFinanceExternalServiceDetailById(this.state.financeAdhocInvoiceId)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            resultTableList: response.responseItem.responseContent.serviceItem,
            resultTableData: response.responseItem.responseContent,
          });
          if (response.responseItem.responseContent.isDrawdown) {
            if (response.responseItem.responseContent.drawdownAmount === 0) {
              this.setState({ isDrawdownComplete: true });
            } else {
              let addData = { ...this.state.addNewDrawdown };
              addData.serviceDrawdownAmount =
                response.responseItem.responseContent.drawdownAmount === null
                  ? 0
                  : response.responseItem.responseContent.drawdownAmount;
              addData.serviceAmount = 0;
              this.setState({
                addNewDrawdown: addData,
                isDrawdownComplete: false,
                specificMonthAmountTotal: 0,
              });
            }
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getFinanceAdhocInvoiceDetail = (id) => {
    this.financeService.getFinanceAdhocInvoiceDetail(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data1 = this.state.invoiceDetail;
        data1.invoiceno = response.responseItem.responseContent.invoiceNo;

        let data = response.responseItem.responseContent;
        if (response.responseItem.responseContent.adhokInvoiceItems && response.responseItem.responseContent.adhokInvoiceItems.length > 0) {
          data.adhokInvoiceItems.financeAccountCodeId = response.responseItem.responseContent.adhokInvoiceItems[0].financeAccountCodeId;
          data.adhokInvoiceItems.financeClassCodeId = response.responseItem.responseContent.adhokInvoiceItems[0].financeClassCodeId;
        }
        this.setState({ invoiceDetail: data1 });
        this.setState(
          {
            adhokInvoiceDetail: data,
            invoiceItemList:
              response.responseItem.responseContent.adhokInvoiceItems,
            isCompleted: response.responseItem.responseContent.isCompleted,
            financeAdhocInvoiceId: id,
          },
          () => {
            this.getAllDropdownsForFinanceAdhokInvoices();
            if (response.responseItem.responseContent.isCompleted === true) {
              this.getFinanceExternalServiceDetailById();
            }
          }
        );
      } else {
        this.getAllDropdownsForFinanceAdhokInvoices();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  // Uncomment for encryption

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let financeAdhocInvoiceId = params.get("");
    if (
      financeAdhocInvoiceId &&
      (financeAdhocInvoiceId = checkParameters(financeAdhocInvoiceId, "Number"))
    ) {
      if (financeAdhocInvoiceId != null && financeAdhocInvoiceId > 0) {
        this.getFinanceAdhocInvoiceDetail(financeAdhocInvoiceId);
        this.getAllDropdownForCorporateMember();
      } else {
        this.getAllDropdownsForFinanceAdhokInvoices();
        this.getAllDropdownForCorporateMember();
      }
    } else {
      this.getAllDropdownsForFinanceAdhokInvoices();
      this.getAllDropdownForCorporateMember();
    }
  }

  // componentDidMount() {
  //   let params = new URLSearchParams(window.location.search);
  //   let financeAdhocInvoiceId = params.get("") ? Number(params.get("")) : 0;
  //   if (financeAdhocInvoiceId != null && financeAdhocInvoiceId > 0) {
  //     this.getFinanceAdhocInvoiceDetail(financeAdhocInvoiceId);
  //   }
  //   this.getAllDropdownsForFinanceAdhokInvoices();
  // }

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.adhokInvoiceDetail,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateField = (key, providedState) => {
    providedState = providedState
      ? providedState
      : this.state.adhokInvoiceDetail;
    const newValidState = validate(
      key,
      providedState,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateInvoiceItemeAllFields = () => {
    const newValidState = isValidForm(
      this.state.adhokInvoiceDetail.adhokInvoiceItems,
      this.state.validationRuleForInvoiceIteme,
      this.state.validState2
    );
    this.setState({ validState2: newValidState });
    return newValidState.isValid;
  };
  validateInvoiceItemeField = (key) => {
    const newValidState = validate(
      key,
      this.state.adhokInvoiceDetail.adhokInvoiceItems,
      this.state.validationRuleForInvoiceIteme,
      this.state.validState2
    );
    this.setState({ validState2: newValidState });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let detail = { ...this.state.adhokInvoiceDetail };
    detail[name] = value;
    this.setState({ adhokInvoiceDetail: detail });
  };

  handleChangeRadioButtons = (event) => {
    const { id, checked } = event.target;
    let detail = { ...this.state.adhokInvoiceDetail };
    if (event.target.name === "GST-Radios") {
      detail.isGSTYes = false;
      detail.isGSTZeroRated = false;
      detail.isGSTNotApplicable = false;
      detail[id] = checked;
    }
    this.setState({ adhokInvoiceDetail: detail }, () => {
      this.countAmount();
    });
  };

  handleChangeInDropdown = (id, drpIdentity) => {
    let detail = { ...this.state.adhokInvoiceDetail };

    if (drpIdentity === "PaymentTerm") {
      //var detail = { ...this.state.adhokInvoiceDetail };
      detail.paymentTermId = id;
      this.validateField("paymentTermId", detail);
      //this.setState({ adhokInvoiceDetail: detail });
      //this.validateField("AdhokInvoiceTypes");
    }

    if (drpIdentity === "AdhokInvoiceTypes") {
      //var detail = { ...this.state.adhokInvoiceDetail };
      detail.adhokInvoiceTypeId = id;
      this.validateField("adhokInvoiceTypeId", detail);
      //this.setState({ adhokInvoiceDetail: detail });
      //this.validateField("AdhokInvoiceTypes");
    }
    if (drpIdentity === "financeAccountCodes") {
      //var detail = { ...this.state.adhokInvoiceDetail };
      detail.adhokInvoiceItems.financeAccountCodeId = id;
      this.validateInvoiceItemeField("financeAccountCodeId");
      //this.setState({ adhokInvoiceDetail: detail });
    }
    if (drpIdentity === "financeClassCodes") {
      //var detail = { ...this.state.adhokInvoiceDetail };
      detail.adhokInvoiceItems.financeClassCodeId = id;
      this.validateInvoiceItemeField("financeClassCodeId");
      //this.setState({ adhokInvoiceDetail: detail });
    }
    if (drpIdentity === "revenueAccountCodes") {
      detail.adhokInvoiceItems.revenueAccountCodeId = id;
      this.validateInvoiceItemeField("revenueAccountCodeId");
    }
    if (drpIdentity === "revenueClassCodes") {
      detail.adhokInvoiceItems.revenueClassCodeId = id;
      this.validateInvoiceItemeField("revenueClassCodeId");
    }
    if (drpIdentity === "AdhokInvoiceCountry") {
      detail.financeAdhocCountryId = id;
      this.setState({ adhokInvoiceDetail: detail }, () => {
        this.validateField(
          "financeAdhocCountryId",
          this.state.adhokInvoiceDetail
        );
      });
    }
    this.setState({ adhokInvoiceDetail: detail });
  };

  handleChangeInvoiceItems = (event) => {
    let { name, value } = event.target;
    let detail = { ...this.state.adhokInvoiceDetail };
    //detail.adhokInvoiceItems[name] = value;
    if (name === "adhokAmount" || name === "adhokQuantity") {
      if (value === 0) {
        detail.adhokInvoiceItems[name] = 0;
      } else {
        detail.adhokInvoiceItems[name] = convertToDecimal(value);
      }
    } else {
      detail.adhokInvoiceItems[name] = value;
    }
    this.setState({ adhokInvoiceDetail: detail });
  };

  handleDrawdownData = (event) => {
    let { value } = event.target;
    let detail = { ...this.state.addNewDrawdown };
    if (value) {
      detail.serviceAmount = Number(value);
    } else {
      detail.serviceAmount = convertToDecimal(value);
    }
    if (value)
      this.setState({
        addNewDrawdown: detail,
        specificMonthAmountTotal: detail.serviceAmount,
      });
  };

  // editInvoiceItems = (keyId) => {
  //   let details = [...this.state.invoiceItemList];
  //   let data = { ...details[keyId] };
  //   let setData = { ...this.state.adhokInvoiceDetail };
  //   //let gstAmount = this.state.gstAmount;
  //   if (data) {
  //     // let count = data.adhokAmount * gstAmount;
  //     // data.adhokAmount = convertToDecimal((count).toFixed(2));
  //     setData.adhokInvoiceItems = data;
  //     this.setState({ adhokInvoiceDetail: setData, isEdit: keyId });
  //   }
  // };

  deleteInvoiceItems = (keyId) => {
    // let gstAmount = this.state.gstAmount;
    let details = [...this.state.invoiceItemList];
    details.splice(keyId, 1);
    // details.map((data)=>{
    //   let count = data.adhokAmount * gstAmount;
    //   data.adhokAmount = convertToDecimal((count).toFixed(2));
    // })
    this.setState({ invoiceItemList: details, isEdit: null }, () => {
      this.countAmount();
    });
  };

  handleRadioButtons = (event) => {
    let { id, checked } = event.target;
    let detail = { ...this.state.serviceDetail };
    let data = [...this.state.addMonthList];
    if (detail[id] !== checked) {
      data = [];
      detail.serviceMonthCount = 0;
    }
    if (id === "isSpecificMonth") {
      let array = { ...this.state.array };
      array.serviceStartMonth = moment().format("yyyy-MM-DD");
      array.revenueAccountCodeId = 0;
      array.revenueClassCodeId = 0;
      data.push(array);
    }
    if (id === "isDrawdown") {
      let array = { ...this.state.array };
      array.serviceDrawdownAmount =
        // this.state.adhokInvoiceDetail.adhokTotalAmount;
        this.state.adhokInvoiceDetail.adhokSubtotalAmount;

      data.push(array);
    }
    detail.isContinuousPeriod = false;
    detail.isSpecificMonth = false;
    detail.isDrawdown = false;
    detail[id] = checked;
    this.setState({
      serviceDetail: detail,
      addMonthList: data,
      specificMonthAmountTotal: 0,
    });
  };

  handleChangeForServiceItem = (event) => {
    let { id, name, value } = event.target;
    let detail = { ...this.state.serviceDetail };
    if (name === "serviceMonthCount") {
      value = Number(value);
      let i = 1;
      // let addNew = [...this.state.addMonthList];
      let addNew = [];
      addNew = [];
      while (i <= value) {
        let date = "";
        if (i !== 1) {
          date = moment(detail.serviceStartMonth).add(i - 1, "months");
        } else {
          date = moment(detail.serviceStartMonth).format("yyyy-MM-DD");
        }

        let array = {};
        //  array.serviceStartMonth = moment(date._d).format("yyyy-MM-DD");
        array.serviceStartMonth = date;
        array.serviceDrawdownAmount = 0; //this.state.adhokInvoiceDetail.adhokTotalAmount;
        array.serviceAmount = 0;
        array.revenueAccountCodeId = 0;
        array.revenueClassCodeId = 0;
        addNew.push(array);
        i = i + 1;
      }
      this.setState({ addMonthList: addNew, specificMonthAmountTotal: 0 });
    }
    if (id === "setDates") {
      if (detail.serviceStartMonth !== value) {
        let addNew = [...this.state.addMonthList];
        addNew.map((set, keyId) => {
          let newDate = "";
          if (keyId === 0) {
            //newDate = moment(value).format("yyyy-MM-DD");
            set.serviceStartMonth = value;
          } else {
            newDate = moment(value).add(keyId + 1, "months");
            set.serviceStartMonth = moment(newDate._d);
          }
        });
        this.setState({ addMonthList: addNew });
      }
    }
    detail[name] = value;
    this.setState({ serviceDetail: detail });
  };

  handleContinuousItemChange = (event) => {
    let { name, value } = event.target;
    let detail = [...this.state.addMonthList];
    let array = name.split("_");
    detail[Number(array[1])].serviceAmount = convertToDecimal(value);
    let amountTotalCount = 0;
    detail.map((item) => {
      amountTotalCount = amountTotalCount + item.serviceAmount;
    });
    this.setState({ specificMonthAmountTotal: amountTotalCount });
    this.validateServiceItemsField(name, detail, Number(array[1]));
    this.setState({ addMonthList: detail });
  };

  handleSpecificMonthItemChange = (event) => {
    let { name, value } = event.target;
    let detail = [...this.state.addMonthList];
    let array = name.split("_");
    if (array[0] === "serviceStartMonth") {
      detail[Number(array[1])].serviceStartMonth = value;
    }
    if (array[0] === "serviceAmount") {
      detail[Number(array[1])].serviceAmount = convertToDecimal(value);
      let amountTotalCount = 0;
      detail.map((item) => {
        amountTotalCount = amountTotalCount + item.serviceAmount;
      });
      this.setState({ specificMonthAmountTotal: amountTotalCount });
    }
    this.setState({ addMonthList: detail });
  };

  addSpecificMonth = () => {
    let detail = [...this.state.addMonthList];
    let addNew = { ...this.state.array };
    detail.push(addNew);
    let amountTotalCount = 0;
    detail.map((item) => {
      amountTotalCount = amountTotalCount + item.serviceAmount;
    });
    this.setState({
      addMonthList: detail,
      specificMonthAmountTotal: amountTotalCount,
    });
  };

  removeSpecificMonth = () => {
    let detail = [...this.state.addMonthList];
    detail.splice(-1);
    let amountTotalCount = 0;
    detail.map((item) => {
      amountTotalCount = amountTotalCount + item.serviceAmount;
    });
    this.setState({
      addMonthList: detail,
      specificMonthAmountTotal: amountTotalCount,
    });
  };

  addEditData = () => {
    if (this.isValidateServiceItems()) {
      let request = { ...this.state.serviceDetail };
      request.createdBy = 114;
      request.createdAppId = 1;
      request.financeAdhocInvoiceId = this.state.financeAdhocInvoiceId;
      request.serviceItem = [...this.state.addMonthList];
      if (request.isDrawdown === true) {
        request.drawdownAmount = convertToDecimal(
          (
            request.serviceItem[0].serviceDrawdownAmount -
            request.serviceItem[0].serviceAmount
          ).toFixed(2)
        );
      } else {
        request.drawdownAmount = 0;
      }
      this.setState({ isLoading: true });
      this.financeService
        .addEditFinanceExternalServiceDetail(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("External invoice added successful.");
            this.setState({ redirect: "/financial/ExternalInvoices" });
          } else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        });
    }
  };

  addDrawdownData = () => {
    let request = { ...this.state.addNewDrawdown };
    request.financeExternalServiceId =
      this.state.resultTableData.financeExternalServiceId;
    request.drawdownAmount = convertToDecimal(
      (request.serviceDrawdownAmount - request.serviceAmount).toFixed(2)
    );
    this.financeService
      .addDrawdownForExternalServiceItem(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("External invoice updated successful.");
          this.setState({ redirect: "/financial/ExternalInvoices" });
        } else {
          this.swalServices.Error(ErrorMessage.DefaultMessage);
        }
        this.setState({ isLoading: false });
      });
  };

  GSTLabel() {
    if (this.state.adhokInvoiceDetail.isGSTYes === true) {
      return (
        <div className="text-xl  theme-color ml-2 text-right">
          <p>GST({this.state.gstPercentage}%):</p>
        </div>
      );
    } else if (this.state.adhokInvoiceDetail.isGSTZeroRated === true) {
      return (
        <div className="text-xl font-medium theme-color ml-2 text-right">
          <p>GST({0}%):</p>
        </div>
      );
    } else if (this.state.adhokInvoiceDetail.isGSTNotApplicable === true) {
      return (
        <div className="text-xl theme-color ml-2 text-right">
          <p>GST:</p>
        </div>
      );
    }
  }

  GSTAmount() {

    const fixedFloatingPoint = (value) => {
      return Number.parseFloat(value).toFixed(2)
    }
    if (this.state.adhokInvoiceDetail.isGSTYes === true) {
      return <p>
        {/* ${fixedFloatingPoint(this.state.adhokInvoiceDetail.adhokGSTAmount)} */}
        {formatPrice(this.state.adhokInvoiceDetail.adhokGSTAmount)}
      </p>;
    } else if (this.state.adhokInvoiceDetail.isGSTZeroRated === true) {
      return <p>
        {/* ${fixedFloatingPoint(this.state.adhokInvoiceDetail.adhokGSTAmount)} */}
        {formatPrice(this.state.adhokInvoiceDetail.adhokGSTAmount)}
      </p>;
    } else if (this.state.adhokInvoiceDetail.isGSTNotApplicable === true) {
      return <p>N.A.</p>;
    }
  }

  disableInput() {
    let isDisabled = false;
    if (
      this.state.adhokInvoiceDetail.checkPaymentStatus === "PaymentDone" ||
      this.state.adhokInvoiceDetail.paymentStatus === "Cancelled" ||
      this.state.adhokInvoiceDetail.paymentStatus === "Pending Cancellation"
    ) {
      isDisabled = true;
    }
    return isDisabled;
  }

  handleChangeInRevDrp = (id, drpIdentity, index) => {
    let dataArr = [...this.state.addMonthList];
    if (index > -1) {
      let dataObj = { ...dataArr[index] };
      dataObj[drpIdentity] = id;
      dataArr[index] = dataObj;
      this.setState({ addMonthList: dataArr });
    }
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section finance-body-sec">
        {/* Section 1 Start */}
        <div className="bg-white">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                General Details
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Invoice Type<span className="text-[#AA3361] ">*</span>
                  </h2>
                  <DropdownSelect
                    drpIdentity="AdhokInvoiceTypes"
                    optionArray={this.state.adhokInvoiceTypes}
                    setFilterParameters={this.handleChangeInDropdown.bind(this)}
                    value={this.state.adhokInvoiceDetail.adhokInvoiceTypeId}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.adhokInvoiceTypeId}
                  />
                </div>
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Company Name<span className="text-[#AA3361]">*</span>
                  </h2>
                  <input
                    id="default"
                    name="companyName"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.companyName}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("companyName")}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.companyName}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Attention To:
                    <span className="text-[#AA3361]">*</span>
                  </h2>
                  <input
                    id="default"
                    name="adhocAttentionTo"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhocAttentionTo}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("adhocAttentionTo")}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.adhocAttentionTo}
                  />
                </div>
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Email Address
                    <span className="text-[#AA3361]">*</span>
                  </h2>
                  <input
                    id="default"
                    name="adhokEmailAddress"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhokEmailAddress}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("adhokEmailAddress")}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.adhokEmailAddress}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Billing Address Line 1
                    <span className="text-[#AA3361]">*</span>
                  </h2>
                  <input
                    id="default"
                    name="adhocBillingAddress1"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhocBillingAddress1}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("adhocBillingAddress1")}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.adhocBillingAddress1}
                  />
                </div>
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Billing Address Line 2
                    {/* <span className="text-[#AA3361]">*</span> */}
                  </h2>
                  <input
                    id="default"
                    name="adhocBillingAddress2"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhocBillingAddress2}
                    onChange={(event) => this.handleChange(event)}
                    // onBlur={() => this.validateField("adhocBillingAddress2")}
                    disabled={this.disableInput()}
                  />
                  {/* <ValidationText
                    error={this.state.validState.error.adhocBillingAddress2}
                  /> */}
                </div>
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Billing Address Line 3
                    {/* <span className="text-[#AA3361]">*</span> */}
                  </h2>
                  <input
                    id="default"
                    name="adhocBillingAddress3"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhocBillingAddress3}
                    onChange={(event) => this.handleChange(event)}
                    // onBlur={() => this.validateField("adhocBillingAddress3")}
                    disabled={this.disableInput()}
                  />
                  {/* <ValidationText
                    error={this.state.validState.error.adhocBillingAddress3}
                  /> */}
                </div>
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Postal Code
                    <span className="text-[#AA3361]">*</span>
                  </h2>
                  <input
                    id="default"
                    name="adhokPostalCode"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhokPostalCode}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={() => this.validateField("adhokPostalCode")}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.adhokPostalCode}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">PO Number</h2>
                  <input
                    id="default"
                    name="adhokPONumber"
                    className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput()
                      ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                      : null
                      }}`}
                    type="text"
                    value={this.state.adhokInvoiceDetail.adhokPONumber}
                    onChange={(event) => this.handleChange(event)}
                    // onBlur={() => this.validateField("adhokPONumber")}
                    disabled={this.disableInput()}
                  />
                  {/* <ValidationText
                    error={this.state.validState.error.adhokPONumber}
                  /> */}
                </div>
                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                  <h2 className="text-xl theme-color mb-2 font-bold">
                    Country
                    <span className="text-[#AA3361]">*</span>
                  </h2>
                  <DropdownSelect
                    drpIdentity="AdhokInvoiceCountry"
                    optionArray={this.state.countryList}
                    setFilterParameters={this.handleChangeInDropdown.bind(this)}
                    value={this.state.adhokInvoiceDetail.financeAdhocCountryId}
                    disabled={this.disableInput()}
                  />
                  <ValidationText
                    error={this.state.validState.error.financeAdhocCountryId}
                  />
                </div>
              </div>
            </div>

            <div className="mt-0 flex items-center 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 ">
              <div className="addexternal-invoice">
                <h2 className="text-xl theme-color mb-2 font-medium">GST</h2>
                <div className="flex">
                  <label className="flex items-center mr-8">
                    <input
                      type="radio"
                      name="GST-Radios"
                      id="isGSTYes"
                      className="form-radio"
                      onChange={(event) =>
                        this.handleChangeRadioButtons(event)
                      }
                      checked={this.state.adhokInvoiceDetail.isGSTYes}
                      disabled={this.disableInput()}
                    />
                    <span className="text-xl  font-medium theme-color ml-2">
                      Yes
                    </span>
                  </label>
                  <label className="flex items-center ml-8 mr-8">
                    <input
                      type="radio"
                      name="GST-Radios"
                      id="isGSTZeroRated"
                      className="form-radio"
                      onChange={(event) =>
                        this.handleChangeRadioButtons(event)
                      }
                      checked={
                        this.state.adhokInvoiceDetail.isGSTZeroRated === true
                      }
                      disabled={this.disableInput()}
                    />
                    <span className="text-xl  font-medium theme-color ml-2">
                      No - Zero Rated
                    </span>
                  </label>
                  <label className="flex items-center ml-8">
                    <input
                      type="radio"
                      name="GST-Radios"
                      id="isGSTNotApplicable"
                      className="form-radio"
                      // value={props.resource.isPage}
                      onChange={(event) =>
                        this.handleChangeRadioButtons(event)
                      }
                      checked={
                        this.state.adhokInvoiceDetail.isGSTNotApplicable ===
                        true
                      }
                      disabled={this.disableInput()}
                    />
                    <span className="text-xl  font-medium theme-color ml-2">
                      No - Not Applicable
                    </span>
                  </label>
                </div>
              </div>

            </div>
            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">
                Payment Term
                <span className="text-[#AA3361]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity="PaymentTerm"
                optionArray={this.state.PaymentTermList}
                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                value={this.state.adhokInvoiceDetail.paymentTermId}
              // disabled={this.disableInput()}
              />
              <ValidationText
                error={this.state.validState.error.paymentTermId}
              />
            </div>
            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">
                Deferred Income Account Code
                <span className="text-[#AA3361]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity="financeAccountCodes"
                optionArray={this.state.financeAccountCodes}
                setFilterParameters={this.handleChangeInDropdown.bind(
                  this
                )}
                value={
                  this.state.adhokInvoiceDetail.adhokInvoiceItems
                    .financeAccountCodeId ? this.state.adhokInvoiceDetail.adhokInvoiceItems
                    .financeAccountCodeId : 0
                }
                onBlur={() =>
                  this.validateInvoiceItemeField(
                    "financeAccountCodeId"
                  )
                }
                disabled={this.state.invoiceItemList.length > 0 ? true : false}
              />
              <ValidationText
                error={
                  this.state.validState2.error.financeAccountCodeId
                }
              />
            </div>
            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">
                Deferred Income Class Code
                <span className="text-[#AA3361]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity="financeClassCodes"
                optionArray={this.state.financeClassCodes}
                setFilterParameters={this.handleChangeInDropdown.bind(
                  this
                )}
                value={
                  this.state.adhokInvoiceDetail.adhokInvoiceItems
                    .financeClassCodeId ? this.state.adhokInvoiceDetail.adhokInvoiceItems
                    .financeClassCodeId : 0
                }
                onBlur={() =>
                  this.validateInvoiceItemeField(
                    "financeClassCodeId"
                  )
                }
                disabled={this.state.invoiceItemList.length > 0 ? true : false}
              />
              <ValidationText
                error={
                  this.state.validState2.error.financeClassCodeId
                }
              />
            </div>





          </div>
          {this.state.adhokInvoiceDetail.checkPaymentStatus !== "PaymentDone" &&
            this.state.adhokInvoiceDetail.paymentStatus !== "Cancelled" &&
            this.state.adhokInvoiceDetail.paymentStatus !==
            "Pending Cancellation" ? (
            <>
              <div className="grid grid-cols-12 gap-6 w-full items-center px-3">
                <div className="col-span-12 border-2 px-5 py-7">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                          <h2 className="text-xl theme-color mb-2 font-bold">
                            Xero Description
                            <span className="text-[#AA3361]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="adhokXeroDescription"
                            className="form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            value={
                              this.state.adhokInvoiceDetail.adhokInvoiceItems
                                .adhokXeroDescription
                            }
                            onChange={(event) =>
                              this.handleChangeInvoiceItems(event)
                            }
                            onBlur={() =>
                              this.validateInvoiceItemeField(
                                "adhokXeroDescription"
                              )
                            }
                          />
                          <ValidationText
                            error={
                              this.state.validState2.error.adhokXeroDescription
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                          <h2 className="text-xl theme-color mb-2 font-bold">
                            Invoice Description
                            <span className="text-[#AA3361]">*</span>
                          </h2>
                          <input
                            id="default"
                            name="adhokInvoiceDescription"
                            className="form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            value={
                              this.state.adhokInvoiceDetail.adhokInvoiceItems
                                .adhokInvoiceDescription
                            }
                            onChange={(event) =>
                              this.handleChangeInvoiceItems(event)
                            }
                            onBlur={() =>
                              this.validateInvoiceItemeField(
                                "adhokInvoiceDescription"
                              )
                            }
                          />
                          <ValidationText
                            error={
                              this.state.validState2.error
                                .adhokInvoiceDescription
                            }
                          />
                        </div>
                        <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                          <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                              <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3">
                                <h2 className="text-xl theme-color mb-2 font-bold">
                                  Quantity
                                  <span className="text-[#AA3361]">*</span>
                                </h2>
                                <input
                                  id="default"
                                  name="adhokQuantity"
                                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                  type="text"
                                  value={
                                    this.state.adhokInvoiceDetail
                                      .adhokInvoiceItems.adhokQuantity
                                  }
                                  onChange={(event) =>
                                    this.handleChangeInvoiceItems(event)
                                  }
                                  onBlur={() =>
                                    this.validateInvoiceItemeField(
                                      "adhokQuantity"
                                    )
                                  }
                                  min="1"
                                />
                                <ValidationText
                                  error={
                                    this.state.validState2.error.adhokQuantity
                                  }
                                />
                              </div>
                              <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3">
                                <h2 className="text-xl theme-color mb-2 font-bold">
                                  Amount
                                  <span className="text-[#AA3361]">*</span>
                                </h2>
                                <input
                                  id="default"
                                  name="adhokAmount"
                                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                  type="text"
                                  value=
                                  // {formatPrice( this.state.adhokInvoiceDetail
                                  //   .adhokInvoiceItems.adhokAmount)}
                                  {
                                    this.state.adhokInvoiceDetail
                                      .adhokInvoiceItems.adhokAmount
                                  }
                                  onChange={(event) =>
                                    this.handleChangeInvoiceItems(event)
                                  }
                                  onBlur={() =>
                                    this.validateInvoiceItemeField(
                                      "adhokAmount"
                                    )
                                  }
                                  min="1"
                                />
                                <ValidationText
                                  error={
                                    this.state.validState2.error.adhokAmount
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6">

                        {/* <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                          <h2 className="text-xl theme-color mb-3">
                            Revenue Account Code
                            <span className="text-[#AA3361]">*</span>
                          </h2>
                          <DropdownSelect
                            drpIdentity="revenueAccountCodes"
                            optionArray={this.state.financeAccountCodes}
                            setFilterParameters={this.handleChangeInDropdown.bind(
                              this
                            )}
                            value={
                              this.state.adhokInvoiceDetail.adhokInvoiceItems
                                .revenueAccountCodeId
                            }
                            onBlur={() =>
                              this.validateInvoiceItemeField(
                                "revenueAccountCodeId"
                              )
                            }
                          />
                          <ValidationText
                            error={
                              this.state.validState2.error.revenueAccountCodeId
                            }
                          />
                        </div>
                        <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                          <h2 className="text-xl theme-color mb-3">
                            Revenue Class Code
                            <span className="text-[#AA3361]">*</span>
                          </h2>
                          <DropdownSelect
                            drpIdentity="revenueClassCodes"
                            optionArray={this.state.financeClassCodes}
                            setFilterParameters={this.handleChangeInDropdown.bind(
                              this
                            )}
                            value={
                              this.state.adhokInvoiceDetail.adhokInvoiceItems
                                .revenueClassCodeId
                            }
                            onBlur={() =>
                              this.validateInvoiceItemeField(
                                "revenueClassCodeId"
                              )
                            }
                          />
                          <ValidationText
                            error={
                              this.state.validState2.error.revenueClassCodeId
                            }
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 items-center">
                        <div className="mt-4 flex items-center justify-end col-span-12 w-full">
                          <button
                            className="btn btn-pink text-xl border text-white w-[175px]"
                            onClick={() => this.addInvoiceItem()}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pb-12">
            <div className="col-span-12 border-b-2 pb-10">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 w-full">
                  <div className="adhoc-invoices-table">
                    {this.state.adhokInvoiceDetail.checkPaymentStatus !==
                      "PaymentDone" &&
                      this.state.adhokInvoiceDetail.paymentStatus !==
                      "Cancelled" &&
                      this.state.adhokInvoiceDetail.paymentStatus !==
                      "Pending Cancellation" ? (
                      <>
                        <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border border-collapse">
                          <thead>
                            <tr className="text-base font-bold flex">
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[52%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Name
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[16%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Quantity
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[16%]">
                                <div className="font-bold tble-th-txt">Amount</div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[16%]">
                                <div className="font-bold tble-th-txt">Actions</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-sm custom-tbody">
                            {this.state.invoiceItemList.map(
                              (invoiceItem, key) => {
                                return (
                                  <tr className="custom-tbl-td flex items-center" key={key}>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[52%] border border-[#181818]">
                                      {invoiceItem.adhokInvoiceDescription}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[16%] border border-[#181818]">
                                      {invoiceItem.adhokQuantity}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[16%] border border-[#181818]">
                                      {/* {Number.parseFloat(invoiceItem.adhokAmount).toFixed(2)} */}
                                      {formatPrice(invoiceItem.adhokAmount)}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[16%] border border-[#181818]">
                                      <div className="flex justify-center">
                                        {/* <button className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                        onClick={() => this.editInvoiceItems(key)}
                                      >
                                        <p className='ml-2 tool-tip-txt underline text-[#181818]'>
                                          Edit
                                        </p>
                                      </button> */}
                                        <button
                                          className="action-btn right-icon"
                                          onClick={() =>
                                            this.deleteInvoiceItems(key)
                                          }
                                        >
                                          <DeleteButtonIcon />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border border-collapse">
                          <thead>
                            <tr className="text-base font-bold flex">
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[60%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Name
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-left">
                                  Quantity
                                </div>
                              </th>
                              <th className="px-2 first:pl-5 last:pr-5 tbl-th border whitespace-nowrap text-white w-[20%]">
                                <div className="font-bold tble-th-txt">Amount</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-sm custom-tbody">
                            {this.state.invoiceItemList.map(
                              (invoiceItem, key) => {
                                return (
                                  <tr className="custom-tbl-td flex items-center" key={key}>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[60%] border border-[#181818]">
                                      {invoiceItem.adhokInvoiceDescription}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[20%] border border-[#181818]">
                                      {invoiceItem.adhokQuantity}
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[20%] border border-[#181818]">
                                      {/* {invoiceItem.adhokAmount} */}
                                      {formatPrice(invoiceItem.adhokAmount)}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-span-12 w-full">
                  <div className="total-section">
                    <div className="mt-0 flex items-center justify-end w-full">
                      <div className="order-total-sec flex">
                        <div className="order-title">
                          <div className="text-xl  theme-color ml-2 text-right">
                            Order Subtotal :
                          </div>
                          {this.GSTLabel()}
                          <div className="text-xl theme-color ml-2 text-right">
                            Total :
                          </div>
                          <div className="text-xl  theme-color ml-2 text-right">
                            Payments/Credits applied :
                          </div>
                          <div className="text-xl font-bold theme-color ml-2 text-right">
                            Net Total :
                          </div>
                        </div>
                        <div className="order-desc ml-24">
                          <div className="text-xl theme-color ml-2 text-right">
                            <p>
                              {/* $
                              {
                                Number.parseFloat(this.state.adhokInvoiceDetail
                                  .adhokSubtotalAmount).toFixed(2)
                              } */}
                              {formatPrice(this.state.adhokInvoiceDetail
                                .adhokSubtotalAmount)}
                            </p>
                          </div>
                          <div className="text-xl  theme-color ml-2 text-right">
                            {this.GSTAmount()}
                          </div>
                          <div className="text-xl  theme-color ml-2 text-right">
                            <p>
                              {/* ${Number.parseFloat(this.state.adhokInvoiceDetail.adhokTotalAmount).toFixed(2)} */}
                              {formatPrice(this.state.adhokInvoiceDetail.adhokTotalAmount)}
                            </p>
                          </div>
                          <div className="text-xl  theme-color ml-2 text-right">
                            <p>

                              {/* {
                                Number.parseFloat(this.state.adhokInvoiceDetail
                                  .paymentsCreditsApplied).toFixed(2)
                              } */}
                              {formatPrice(this.state.adhokInvoiceDetail
                                .paymentsCreditsApplied)}
                            </p>
                          </div>
                          <div className="text-xl font-bold theme-color ml-2 text-right">
                            <p>
                              {/* ${Number.parseFloat(this.state.adhokInvoiceDetail.netTotal).toFixed(2)} */}
                              {formatPrice(this.state.adhokInvoiceDetail.netTotal)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8">
            <div className="col-span-12">
              <div className="grid grid-cols-12 items-center">
                <div
                  className={
                    this.state.financeAdhocInvoiceId
                      ? "mt-4 flex items-center justify-end col-span-12 w-full"
                      : "flex items-center justify-end col-span-12 w-full my-4"
                  }
                >
                  {this.state.submitExternalInvoice === true ? (
                    <ButtonLoader />
                  ) : (
                    // className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.disableInput() ? 'cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]' : null}}`}
                    <button
                      className={`btn btn-pink text-xl border text-white w-[175px] ${this.disableInput()
                        ? "cursor-not-allowed bg-[#e9e9ea]"
                        : null
                        }`}
                      onClick={() => this.addEditFinanceAdhokInvoiceDetail()}
                      disabled={!!this.disableInput()}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.state.financeAdhocInvoiceId ? (
            <>
              <div className="grid grid-cols-12 gap-6 w-full items-center px-8 mt-3">
                <div className="col-span-12">
                  <div className="mt-5 flex items-center">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="isRadio"
                        id="isContinuousPeriod"
                        className="form-radio"
                        value={this.state.serviceDetail.isContinuousPeriod}
                        onChange={(event) => this.handleRadioButtons(event)}
                        checked={
                          this.state.resultTableData.isContinuousPeriod
                            ? true
                            : this.state.serviceDetail.isContinuousPeriod ===
                            true
                        }
                        disabled={
                          !!(this.state.adhokInvoiceDetail.checkPaymentStatus !==
                            "PaymentDone" ||
                            this.state.adhokInvoiceDetail.isCompleted === true)
                        }
                      />
                      <span className="text-xl  font-medium theme-color ml-2">
                        Continuous Period
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-span-12">
                  {this.state.serviceDetail.isContinuousPeriod ? (
                    <>
                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                          <h2 className="text-xl theme-color mb-3">
                            Starting Month
                          </h2>
                          <input
                            id="setDates"
                            name="serviceStartMonth"
                            className={`date-pickers form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.adhokInvoiceDetail
                              .checkPaymentStatus !== "PaymentDone" ||
                              this.state.serviceDetail.isContinuousPeriod !==
                              true
                              ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                              : null
                              }}`}
                            type="date"
                            onChange={(event) =>
                              this.handleChangeForServiceItem(event)
                            }
                            value={this.state.serviceDetail.serviceStartMonth}
                            disabled={
                              !!(this.state.adhokInvoiceDetail
                                .checkPaymentStatus !== "PaymentDone" ||
                                this.state.serviceDetail.isContinuousPeriod !==
                                true)
                            }
                          />
                        </div>
                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                          <h2 className="text-xl theme-color mb-3">
                            Number of Month
                          </h2>
                          <input
                            id="default"
                            name="serviceMonthCount"
                            className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.adhokInvoiceDetail
                              .checkPaymentStatus !== "PaymentDone" ||
                              this.state.serviceDetail.isContinuousPeriod !==
                              true
                              ? "cursor-not-allowed bg-[#e9e9ea] border-[#181818]  text-[#181818]"
                              : null
                              }}`}
                            type="text"
                            onChange={(event) =>
                              this.handleChangeForServiceItem(event)
                            }
                            min={0}
                            disabled={
                              !!(this.state.adhokInvoiceDetail
                                .checkPaymentStatus !== "PaymentDone" ||
                                this.state.serviceDetail.isContinuousPeriod !==
                                true)
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {this.state.addMonthList &&
                    this.state.serviceDetail.isContinuousPeriod === true &&
                    this.state.addMonthList.map((item, keyId) => {
                      return (
                        <>
                          <div className="grid grid-cols-12 gap-6" key={keyId}>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Start Month
                              </h2>
                              <input
                                id="amount"
                                name="serviceStartMonth"
                                className={`date-pickers form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.adhokInvoiceDetail
                                  .checkPaymentStatus !== "PaymentDone"
                                  ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                                  : null
                                  }}`}
                                type="date"
                                value={moment(item.serviceStartMonth).format(
                                  "yyyy-MM-DD"
                                )}
                                disabled={true}
                              />
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Amount
                              </h2>
                              <input
                                id="amount"
                                name={`serviceAmount_${keyId}`}
                                className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.adhokInvoiceDetail
                                  .checkPaymentStatus !== "PaymentDone"
                                  ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                                  : null
                                  }}`}
                                type="text"
                                onChange={(event) =>
                                  this.handleContinuousItemChange(event)
                                }
                                disabled={
                                  this.state.adhokInvoiceDetail
                                    .checkPaymentStatus !== "PaymentDone"
                                }
                              // onBlur={(e) =>
                              //   this.validateServiceItemsField(
                              //     `serviceAmount_${keyId}`,
                              //     item,
                              //     keyId
                              //   )
                              // }
                              />
                              <ValidationText
                                error={
                                  this.state.valid.error[
                                  `serviceAmount_${keyId}`
                                  ]
                                }
                              />
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Revenue Account Code
                                <span className="text-[#AA3361]">*</span>
                              </h2>
                              <DropdownSelect
                                drpIdentity="revenueAccountCodeId"
                                optionArray={this.state.financeAccountCodes}
                                setFilterParameters={(id, drpIdentity) => this.handleChangeInRevDrp(id, drpIdentity, keyId)}
                                value={item.revenueAccountCodeId}
                                onBlur={() => this.validateInvoiceItemeField("revenueAccountCodeId")}
                              />
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Revenue Class Code
                                <span className="text-[#AA3361]">*</span>
                              </h2>
                              <DropdownSelect
                                drpIdentity="revenueClassCodeId"
                                optionArray={this.state.financeClassCodes}
                                setFilterParameters={(id, drpIdentity) => this.handleChangeInRevDrp(id, drpIdentity, keyId)}
                                value={item.revenueClassCodeId}
                                onBlur={() => this.validateInvoiceItemeField("revenueClassCodeId")}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pb-10 mt-3">
                <div className="col-span-12 mt-8">
                  <div className="grid grid-cols-12 gap-6 ">
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 flex items-center">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="isRadio"
                          id="isSpecificMonth"
                          className="form-radio"
                          value={this.state.serviceDetail.isSpecificMonth}
                          onChange={(event) => this.handleRadioButtons(event)}
                          checked={
                            this.state.resultTableData.isSpecificMonth
                              ? true
                              : this.state.serviceDetail.isSpecificMonth ===
                              true
                          }
                          disabled={
                            !!(this.state.adhokInvoiceDetail.checkPaymentStatus !==
                              "PaymentDone" ||
                              this.state.adhokInvoiceDetail.isCompleted === true)
                          }
                        />
                        <span className="text-xl  font-medium theme-color ml-2">
                          Specific Month
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  {this.state.addMonthList &&
                    this.state.serviceDetail.isSpecificMonth === true &&
                    this.state.addMonthList.map((item, keyId) => {
                      return (
                        <div className="grid grid-cols-12 gap-6" key={keyId}>
                          <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                            <h2 className="text-xl theme-color mb-3">Month</h2>
                            <input
                              id="specificMonthDate"
                              name={`serviceStartMonth_${keyId}`}
                              className={`date-pickers form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.adhokInvoiceDetail
                                .checkPaymentStatus !== "PaymentDone"
                                ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                                : null
                                }}`}
                              type="date"
                              value={item.serviceStartMonth}
                              onChange={(event) =>
                                this.handleSpecificMonthItemChange(event)
                              }
                            />
                            <ValidationText
                              error={
                                this.state.valid.error[
                                `serviceStartMonth_${keyId}`
                                ]
                              }
                            />
                          </div>
                          <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                            <h2 className="text-xl theme-color mb-3">Amount</h2>
                            <input
                              id="specificMonthDate"
                              name={`serviceAmount_${keyId}`}
                              className={`form-input rounded-none w-full shadow-red py-3 text-lg ${this.state.adhokInvoiceDetail
                                .checkPaymentStatus !== "PaymentDone"
                                ? "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]"
                                : null
                                }}`}
                              type="text"
                              value={item.serviceMonthCount}
                              onChange={(event) =>
                                this.handleSpecificMonthItemChange(event)
                              }
                              disabled={
                                this.state.adhokInvoiceDetail
                                  .checkPaymentStatus !== "PaymentDone"
                              }
                            />
                            <ValidationText
                              error={
                                this.state.valid.error[`serviceAmount_${keyId}`]
                              }
                            />
                          </div>
                          <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                            <h2 className="text-xl theme-color mb-3">
                              Revenue Account Code
                              <span className="text-[#AA3361]">*</span>
                            </h2>
                            <DropdownSelect
                              drpIdentity="revenueAccountCodeId"
                              optionArray={this.state.financeAccountCodes}
                              setFilterParameters={(id, drpIdentity) => this.handleChangeInRevDrp(id, drpIdentity, keyId)}
                              value={item.revenueAccountCodeId}
                              onBlur={() => this.validateInvoiceItemeField("revenueAccountCodeId")}
                            />
                          </div>
                          <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                            <h2 className="text-xl theme-color mb-3">
                              Revenue Class Code
                              <span className="text-[#AA3361]">*</span>
                            </h2>
                            <DropdownSelect
                              drpIdentity="revenueClassCodeId"
                              optionArray={this.state.financeClassCodes}
                              setFilterParameters={(id, drpIdentity) => this.handleChangeInRevDrp(id, drpIdentity, keyId)}
                              value={item.revenueClassCodeId}
                              onBlur={() => this.validateInvoiceItemeField("revenueClassCodeId")}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {this.state.serviceDetail.isSpecificMonth === true ? (
                  <>
                    <div className="col-span-12">
                      <div className="flex item-center justify-between pl-7">
                        <button
                          // className={`btn btn-pink-border text-xl font-bold border text-white px-6 ${this.state.adhokInvoiceDetail.adhokTotalAmount >
                          className={`btn btn-pink-border text-xl font-bold border text-white px-6 ${this.state.adhokInvoiceDetail.adhokSubtotalAmount >

                            this.state.specificMonthAmountTotal
                            ? ""
                            : "cursor-not-allowed bg-[#e9e9ea]  border-[#181818]"
                            }}`}
                          onClick={() => {
                            this.addSpecificMonth();
                          }}
                          disabled={
                            // this.state.adhokInvoiceDetail.adhokTotalAmount <= this.state.specificMonthAmountTotal
                            this.state.adhokInvoiceDetail.adhokSubtotalAmount <= this.state.specificMonthAmountTotal

                          }
                        >
                          <span className="mr-3 fa fa-plus"></span>
                          Add Month
                        </button>
                        <button
                          className="btn btn-pink-border text-xl font-bold border text-white px-6"
                          onClick={() => {
                            this.removeSpecificMonth();
                          }}
                        >
                          <span className="mr-3 fa fa-plus"></span>
                          Remove Month
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pb-10 mt-3">
                <div className="col-span-12 flex items-center">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="isDrawdown"
                      id="isDrawdown"
                      className="form-radio"
                      value={this.state.serviceDetail.isDrawdown}
                      onChange={(event) => this.handleRadioButtons(event)}
                      checked={
                        this.state.resultTableData.isDrawdown
                          ? true
                          : this.state.serviceDetail.isDrawdown === true
                      }
                      disabled={
                        !!(this.state.adhokInvoiceDetail.checkPaymentStatus !==
                          "PaymentDone" || this.state.isCompleted)
                      }
                    />
                    <span className="text-xl  font-medium theme-color ml-2">
                      Drawdown
                    </span>
                  </label>
                </div>
                {this.state.addMonthList &&
                  this.state.serviceDetail.isDrawdown === true &&
                  this.state.addMonthList.map((item, keyId) => {
                    return (
                      <>
                        <div className="col-span-12" key={keyId}>
                          <div className="grid grid-cols-12 gap-6">
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Drawdown Amount
                              </h2>
                              <input
                                id="default"
                                name={`serviceDrawdownAmount_${keyId}`}
                                className="form-input bg-[#e3e3e3] rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed"
                                type="text"
                                value=
                                {item.serviceDrawdownAmount}
                                //onChange={(event) => this.handleDrawdownChange(event)}
                                disabled={true}
                              />
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Amount
                              </h2>
                              <input
                                id="default"
                                name={`serviceAmount_${keyId}`}
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                value={item.serviceAmount}
                                onChange={(event) =>
                                  this.handleContinuousItemChange(event)
                                }
                              />
                              {/* <ValidationText
                              error={this.state.valid.error[`serviceAmount_${keyId}`]}
                              /> */}
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Revenue Account Code
                                <span className="text-[#AA3361]">*</span>
                              </h2>
                              <DropdownSelect
                                drpIdentity="revenueAccountCodeId"
                                optionArray={this.state.financeAccountCodes}
                                setFilterParameters={(id, drpIdentity) => this.handleChangeInRevDrp(id, drpIdentity, keyId)}
                                value={item.revenueAccountCodeId}
                                onBlur={() => this.validateInvoiceItemeField("revenueAccountCodeId")}
                              />
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                              <h2 className="text-xl theme-color mb-3">
                                Revenue Class Code
                                <span className="text-[#AA3361]">*</span>
                              </h2>
                              <DropdownSelect
                                drpIdentity="revenueClassCodeId"
                                optionArray={this.state.financeClassCodes}
                                setFilterParameters={(id, drpIdentity) => this.handleChangeInRevDrp(id, drpIdentity, keyId)}
                                value={item.revenueClassCodeId}
                                onBlur={() => this.validateInvoiceItemeField("revenueClassCodeId")}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {this.state.adhokInvoiceDetail.isCompleted &&
                  !this.state.isDrawdownComplete &&
                  this.state.resultTableData &&
                  this.state.resultTableData.isDrawdown ? (
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                        <h2 className="text-xl theme-color mb-3">
                          Drawdown Balance
                        </h2>
                        <input
                          id="default"
                          name="serviceDrawdownAmount"
                          className="form-input bg-[#e3e3e3] rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed"
                          type="text"
                          value=
                          {
                            this.state.addNewDrawdown.serviceDrawdownAmount
                          }
                          //onChange={(event) => this.handleDrawdownChange(event)}
                          disabled={true}
                        />
                      </div>
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 pl-7 input-sec-texts">
                        <h2 className="text-xl theme-color mb-3">Amount</h2>
                        <input
                          id="default"
                          name="serviceAmount"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="text"
                          onChange={(event) => this.handleDrawdownData(event)}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pb-12">
                <div className="col-span-12">
                  <div className="flex item-center justify-between pl-7">
                    <button
                      className={`btn text-xl btn-pink border text-white w-[175px]`}
                      onClick={() => {
                        this.setState({
                          redirect: "/financial/ExternalInvoices",
                        });
                      }}
                    >
                      Back
                    </button>
                    {this.state.serviceDetail.isDrawdown ||
                      this.state.resultTableData.isDrawdown ? (
                      <>
                        {this.state.resultTableData.isDrawdown ? (
                          <>
                            {this.state.isDrawdownComplete ? null : (
                              <div className="flex items-center justify-end w-full">
                                <button
                                  className={`btn text-xl btn-pink border text-white w-[175px] ${this.state.addNewDrawdown
                                    .serviceDrawdownAmount >=
                                    this.state.specificMonthAmountTotal &&
                                    this.state.specificMonthAmountTotal !== 0
                                    ? ""
                                    : "cursor-not-allowed"
                                    }`}
                                  onClick={() => {
                                    this.addDrawdownData();
                                  }}
                                  disabled={
                                    !!(isNullNumber(
                                      this.state.specificMonthAmountTotal
                                    ) === 0 ||
                                      isNullNumber(
                                        this.state.addNewDrawdown.serviceAmount
                                      ) === 0 ||
                                      this.state.specificMonthAmountTotal >
                                      this.state.addNewDrawdown
                                        .serviceDrawdownAmount)
                                  }
                                >
                                  Update
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="flex items-center justify-end w-full">
                              {/* {this.state.addMonthList[0]
                                      .serviceDrawdownAmount} */}
                              <button
                                disabled={
                                  !!(isNullNumber(
                                    this.state.specificMonthAmountTotal
                                  ) === 0 ||
                                    isNullNumber(
                                      this.state.addMonthList[0].serviceAmount
                                    ) === 0 ||
                                    this.state.specificMonthAmountTotal >
                                    this.state.addMonthList[0]
                                      .serviceDrawdownAmount)
                                }
                                className={`btn text-xl btn-pink border text-white w-[175px] ${isNullNumber(
                                  this.state.specificMonthAmountTotal
                                ) === 0 ||
                                  isNullNumber(
                                    this.state.addMonthList[0].serviceAmount
                                  ) === 0 ||
                                  this.state.specificMonthAmountTotal >
                                  this.state.addMonthList[0]
                                    .serviceDrawdownAmount
                                  ? "cursor-not-allowed"
                                  : ""
                                  }`}
                                onClick={() => {
                                  this.addEditData();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex items-center justify-end w-full">
                          <button
                            // className={`btn text-xl btn-pink border text-white w-[175px] ${this.state.adhokInvoiceDetail.adhokTotalAmount ===
                            className={`btn text-xl btn-pink border text-white w-[175px] ${this.state.adhokInvoiceDetail.adhokSubtotalAmount ===
                              this.state.specificMonthAmountTotal
                              ? ""
                              : "cursor-not-allowed"
                              }`}
                            disabled={
                              // this.state.adhokInvoiceDetail.adhokTotalAmount !==
                              this.state.adhokInvoiceDetail.adhokSubtotalAmount !==
                              this.state.specificMonthAmountTotal
                            }
                            onClick={() => {
                              this.addEditData();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        {/* Section 1 End */}
        {/* Result Table  */}
        {this.state.financeAdhocInvoiceId ? (
          <>
            <div className="result-tble px-8 mt-10">
              <div className="grid grid-cols-12 gap-6 w-full items-center">
                <div className="col-span-12">
                  <div className="sm:flex sm:justify-between sm:items-center">
                    <div className="mb-4 sm:mb-0">
                      <h1 className="p-0 table-title-txt theme-color font-bold">
                        Result
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 w-full">
                  {this.state.isCompleted &&
                    (this.state.resultTableData.isContinuousPeriod ||
                      this.state.resultTableData.isSpecificMonth) ? (
                    <>
                      <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table">
                        <thead>
                          <tr className="text-base font-bold flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[35%]">
                              <div className="font-bold tble-th-txt text-left">
                                Amount
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                              <div className="font-bold tble-th-txt text-left">
                                Day
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                              <div className="font-bold tble-th-txt">Month</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                              <div className="font-bold tble-th-txt">Year</div>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="text-sm custom-tbody">
                          {
                            this.state.resultTableList?.map((item, key) => {
                              return (
                                <tr className="custom-tbl-td flex items-center" key={key}>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[35%] ">
                                    {/* {item.serviceAmount} */}
                                    {formatPrice(item.serviceAmount)}
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[25%]">
                                    {moment(item.serviceStartMonth).format(
                                      "ddd"
                                    )}
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[25%]">
                                    {moment(item.serviceStartMonth).format(
                                      "MMM"
                                    )}
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[15%]">
                                    {moment(item.serviceStartMonth).format(
                                      "YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                  {this.state.isCompleted &&
                    this.state.resultTableData.isDrawdown ? (
                    <>
                      <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table">
                        <thead>
                          <tr className="text-base font-bold flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[50%]">
                              <div className="font-bold tble-th-txt text-left">
                                Drawdown Amount
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[50%]">
                              <div className="font-bold tble-th-txt text-left">
                                Amount
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[50%]">
                              <div className="font-bold tble-th-txt text-left">
                                Date
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="text-sm custom-tbody">
                          {
                            this.state.resultTableList?.map((item, key) => {
                              return (
                                <tr className="custom-tbl-td flex items-center" key={key}>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[50%] ">
                                    {/* {item.serviceDrawdownAmount} */}
                                    {formatPrice(item.serviceDrawdownAmount)}
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[50%]">
                                    {/* {item.serviceAmount} */}
                                    {formatPrice(item.serviceAmount)}
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[50%]">
                                    {moment(item.date).format("yyyy-MM-DD")}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                  {/* <tr className="grid justify-items-center">
              <td className="text-lg text-[#181818]	">No Records Found</td>
            </tr> */}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}
