import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import Table from "../../components/table/Table";

export default class TransactionReport extends Component {
    constructor(props) {
        super(props);
        this.financeServices = new FinanceServices();
        this.swalServices = new SwalServices();
        this.state = {
            options: [
                {
                    value: 1,
                    label: "Event",
                },
                {
                    value: 2,
                    label: "Membership",
                },
                {
                    value: 3,
                    label: "External Invoice",
                }
            ],
            transactionRecordList: [],
            transactionRecordRequest: {
                startDate: "",
                endDate: "",
                transactionReportFor: ""
            },
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
            },
            isLoading: false,
            totalCount: 0,
            totalResultes: 0,
            previousReportFor: "",
            previousReportStartDate: "",
            previousReportEndDate: "",
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for transaction report"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for transaction report"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "startDate",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                    }
                ],
                transactionReportFor: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "type of transaction report"),
                    },
                ],
            },
        };
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.transactionRecordRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.transactionRecordRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    handleChangeInDropdown(selected, drpIdentity) {
        if (drpIdentity === "TransactionReportType") {
            let dataArr = this.state.transactionRecordRequest
            if (selected.value > 0) {
                dataArr.transactionReportFor = selected.label;
            } else {
                dataArr.transactionReportFor = "";
            }
            this.setState({ transactionRecordRequest: dataArr }, () => {
                this.validateField("transactionReportFor");
            })
        }
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'startDate') {
            let date = this.state.transactionRecordRequest
            date.startDate = value
            this.setState({ startDate: date })
        }
        if (name === 'endDate') {
            let date = this.state.transactionRecordRequest
            date.endDate = value
            this.setState({ endDate: date })
        }
    }

    getTransactionRecordList = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            if (this.state.transactionRecordRequest.startDate && this.state.transactionRecordRequest.endDate) {
                this.setState({ isLoading: true });
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.transactionRecordRequest.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.transactionRecordRequest.endDate).format("DD MMM YYYY");
                request.transactionReportFor = this.state.transactionRecordRequest.transactionReportFor;
                this.financeServices.getTransactionRecordList(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem !== null) {
                        if (response.responseItem.responseContent !== null) {
                            let details = response.responseItem.responseContent.itemList
                            if (details.length > 0) {
                                details.map((data) => {
                                    data.totalPrice = data.totalPrice > 0 ? (data.totalPrice).toFixed(2) : 0;
                                    data.receiptDate = data.receiptDate ? moment(data.receiptDate).format("DD MMM YYYY") : ""
                                })
                            }
                            this.setState({
                                transactionRecordList: details,
                                totalCount: response.responseItem.responseContent.totalCount,
                                totalResultes: response.responseItem.responseContent.totalCount,
                                previousReportFor: this.state.transactionRecordRequest.transactionReportFor,
                                previousReportStartDate: this.state.transactionRecordRequest.startDate,
                                previousReportEndDate: this.state.transactionRecordRequest.endDate
                            })
                        }
                    }
                    else if (response.statusCode === 409) {
                        this.swalServices.Error(response.message);
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getTransactionRecordList()
    };

    downloadSheetForTransactionRecord = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.transactionRecordRequest.startDate && this.state.transactionRecordRequest.endDate) {
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.transactionRecordRequest.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.transactionRecordRequest.endDate).format("DD MMM YYYY");
                request.transactionReportFor = this.state.transactionRecordRequest.transactionReportFor;
                this.financeServices.downloadSheetForTransactionRecord(request).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        this.setState({
                            previousReportFor: this.state.transactionRecordRequest.transactionReportFor,
                            previousReportStartDate: this.state.transactionRecordRequest.startDate,
                            previousReportEndDate: this.state.transactionRecordRequest.endDate
                        })
                        let blob = new Blob([response.data])
                        saveAs(blob, `${request.transactionReportFor}_TransactionReport` + ".xlsx");
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="lg:col-span-3 col-span-12">
                            <label
                                className="block text-xl font-bold 2xl:mb-2 mb-2 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Start Date<span className="text-[#AA3361]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="startDate"
                                    name="startDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.transactionRecordRequest.startDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("startDate")}
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.startDate} />
                        </div>
                        <div className="lg:col-span-3 col-span-12">
                            <label
                                className="block text-xl font-bold 2xl:mb-2 mb-2  lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                End Date<span className="text-[#AA3361]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="endDate"
                                    name="endDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.transactionRecordRequest.endDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("endDate")}
                                // min={this.state.transactionRecordRequest.startDate}
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.endDate} />
                        </div>

                        <div className="lg:col-span-3 col-span-12">
                            <label
                                className="block text-xl font-bold 2xl:mb-2 mb-2 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Transaction Report For<span className="text-[#AA3361]">*</span>
                            </label>
                            <div className="">
                                <Selector
                                    options={this.state.options}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={this.handleChangeInDropdown.bind(this)}
                                    allowSelectAll={false}
                                    value={this.state.options.filter(item => item.label === this.state.transactionRecordRequest.transactionReportFor)}
                                    drpIdentity="TransactionReportType"
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.transactionReportFor} />
                        </div>

                        <div className="col-span-4">
                            <button className="btn btn-pink text-xl border text-white mt-8" onClick={() => this.getTransactionRecordList()}>
                                Show Transaction Report
                            </button>
                        </div>
                        {this.state.transactionRecordList &&
                            this.state.transactionRecordList.length > 0 &&
                            this.state.previousReportFor === this.state.transactionRecordRequest.transactionReportFor &&
                            this.state.previousReportStartDate === this.state.transactionRecordRequest.startDate &&
                            this.state.previousReportEndDate === this.state.transactionRecordRequest.endDate ?
                            <div className="col-span-4">
                                <button className="btn btn-pink text-xl border text-white mt-8" onClick={() => this.downloadSheetForTransactionRecord()}>
                                    Download Excel
                                </button>
                            </div>
                            : null}
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <Table
                                    columns={[
                                        { name: "invoiceNo", title: "Invoice No", },
                                        { name: "paymentStatus", title: "Payment Status" },
                                        { name: "paymentVia", title: "payment Mode" },
                                        { name: "totalPrice", title: "Total Invoice Amount" },
                                        { name: "voucherCode", title: "Voucher Code" },
                                        { name: "receiptDate", title: "Payment Date" },
                                        { name: "transactionId", title: "Order Id" },
                                    ]}
                                    pagination={this.state.pagination}
                                    rows={this.state.transactionRecordList}
                                    sortingColumns={[""]}
                                    isLoading={this.state.isLoading}
                                    totalResultes={this.state.totalResultes}
                                    totalCount={this.state.totalCount}
                                    setPagination={this.setPagination.bind(this)}
                                />}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
